import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";

import CharacterSelectionApp from "./CharacterSelectionApp";
import EditorApp from "./EditorApp";
import LessonList from "./LessonList";
import ThreeScene from "./ThreeScene";
import LessonEditor from "./LessonEditor";
import FreestyleCode from "./FreestyleCode";
import MainPage from "./MainPage";
import Important from "./Important";
import Contact from "./Contact";

// Import Bootstrap CSS (from node_modules)
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/img/logo.png" // Replace with the path to your logo image
              alt="Logo"
              width="100" // Adjust as needed
              height="100" // Adjust as needed
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mx-auto justify-content-center">
              <Nav.Link as={Link} to="/">Start</Nav.Link>
              <Nav.Link as={Link} to="/ll">Lista Lecții</Nav.Link>
              <Nav.Link as={Link} to="/fc">Codează Liber</Nav.Link>
              <Nav.Link as={Link} to="/imp">Important</Nav.Link>
              <Nav.Link as={Link} to="/ct">Contactează-ne</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <div>
        <Routes>
          <Route path="/" element={<CharacterSelectionApp />} />
          <Route path="/editor" element={<EditorApp />} />
          <Route path="/ll" element={<LessonList />} />
          <Route path="/le" element={<LessonEditor />} />
          <Route path="/ts" element={<ThreeScene />} />
          <Route path="/lectii/:lessonSetId/:lessonId" element={<EditorApp />} />
          <Route path="/fc" element={<FreestyleCode />} />
          <Route path="/mp" element={<MainPage />} />
          <Route path="/imp" element={<Important />} />
          <Route path="/ct" element={<Contact />} />
        </Routes>
      </div>

      <footer style={styles.footer}>
        Realizat de proiecteinformatica.ro
      </footer>
    </Router>
  );
}

const styles = {
  footer:{
    width: '300px',
    margin: '5px auto',
    display: 'none'
  }
};

export default App;

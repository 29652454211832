import React, { useState, useEffect, useRef } from "react";

const CharacterSelectionApp = () => {


  const handleStartGame = () => {
    const url = `./ll`;
    window.location.href = url;
  };

  // Componenta Header
  function Header() {
    return (
        <header style={styles.section}>
            <h1>Meditatii la Informatica in Limbajul C</h1>
            
            <p>Descoperă cea mai eficientă metodă de a învăța limbajul C prin lecții interactive, cod practic și quiz-uri captivante!</p>
        </header>
    );
  }

  // Componenta pentru secțiunea "Ce oferim?"
  function SectionOferim() {
    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <h2>Ce oferim?</h2>
                <div style={styles.features}>
                    <Feature
                        title="Lecții Structurate"
                        description="Parcurge lecții detaliate care te vor ghida de la bazele limbajului C până la concepte avansate. Învață în ritmul tău!"
                    />
                    <Feature
                        title="Exemple de Cod"
                        description="Accesează exemple practice de cod pentru fiecare lecție. Testează, modifică și învață direct din cod!"
                    />
                    <Feature
                        title="Quiz-uri Interesante"
                        description="Testează-ți cunoștințele după fiecare lecție cu ajutorul quiz-urilor noastre. Verifică-ți progresul în timp real!"
                    />

                    <Feature
                        title="Lectii premium pe baza de abonament"
                        description="Foloseste formularul de plata pentru a primi un cod valabil timp de o luna si primesti acces complet la lectiile de pe site!"
                    />
                </div>
            </div>
        </section>
    );
  }

  // Componenta pentru un singur feature
  function Feature({ title, description }) {
    return (
        <div style={styles.feature}>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
  }

  // Componenta pentru secțiunea "Cum funcționează?"
  function SectionCumFunctioneaza() {
    return (
        <section style={styles.header}>
            <div style={styles.container}>
                <h2>Cum funcționează?</h2>
                <p>
                  Poți accesa gratuit o serie de lecții introductive pentru a învăța bazele limbajului C.
                </p>
                <p>
                  Pentru a beneficia de toate lecțiile, inclusiv cele avansate, poți opta pentru
                  achitarea unei taxe. 
                  După efectuarea plății, vei primi un cod de acces
                  care îți va permite să explorezi toate resursele noastre timp de 1 luna. 
                </p>
                <p>
                  Astfel, vei avea la dispoziție tot ce îți trebuie pentru a 
                  deveni un expert în limbajul C!
                </p>
            </div>
        </section>
    );
  }

  // Componenta pentru secțiunea "Începe acum!"
  function SectionIncepe() {
    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <p>
                    <a href="./" style={styles.ctaButton}>Vreau sa invat C</a>
                </p>
            </div>
        </section>
    );
  }

  // Componenta Footer
  function Footer() {
    return (
        <footer style={styles.footer}>
            <p>&copy; 2024 Învață Limbajul C. Toate drepturile rezervate.</p>
        </footer>
    );
  }


  return (
    <div style={styles.body}>
        <Header />
        <SectionIncepe />
        <SectionOferim />
    </div>
  );
};

const styles = {
  body: {
    overflowY: "hidden",
    position: "relative",
    background: "rgb(2,0,36)",
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(180,255,255,1) 0%, rgba(255,255,255,1) 100%)",
    textAlign: "center",
    padding: "20px",
  },
  logo: {
    maxWidth: "20%",
    maxHeight: "20%",
    display: "block",
    margin: "20px auto",
  },
  copii: {
    width: "365px",
    margin: "10px auto",
  },
  characterImage: {
    display: "inline-block",
    width: "40px",
    height: "60px",
    cursor: "pointer",
    padding: "8px",
  },
  border: {
    border: "3px solid black",
    borderRadius: "10px",
  },
  text: {
    width: "140px",
    margin: "5px auto",
    textAlign: "center",
  },
  input: {
    display: "block",
    margin: "5px auto",
    textAlign: "center",
    width: "140px",
  },
  button: {
    display: "block",
    width: "100px",
    margin: "20px auto",
    padding: "10px",
  },
  header: {
    backgroundColor: 'rgb(0, 123, 255)',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
  },
  section: {
      padding: '20px',
  },
  container: {
      maxWidth: '1200px',
      margin: '0 auto',
  },
  features: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      marginTop: '20px',
  },
  feature: {
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      flex: '1 1 calc(33% - 20px)',
      padding: '20px',
      textAlign: 'center',
  },
  ctaButton: {
    backgroundColor: 'rgb(0, 123, 255)',
    color: '#fff',
      padding: '10px 20px',
      textDecoration: 'none',
      borderRadius: '5px',
  },
  footer: {
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      padding: '10px',
      position: 'fixed',
      bottom: '0',
      width: '100%',
  }
};

export default CharacterSelectionApp;

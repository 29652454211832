import React, { useState, useEffect, useRef } from "react";

const CharacterSelectionApp = () => {
  const [selectedCharacter, setSelectedCharacter] = useState("copil1");
  const [name, setName] = useState("");
  const [cod, setCod] = useState("");

  const handleCharacterClick = (characterId) => {
    setSelectedCharacter(characterId);
  };

  

  const handleStartGame = () => {
    const characterName = name || 'Student';
    const url = `./ll?copil=${selectedCharacter}&nume=${characterName}&cod=${cod}`;
    window.location.href = url;

    localStorage.setItem('copil', selectedCharacter);
    localStorage.setItem('nume', characterName);
    localStorage.setItem('cod', cod);

  };

  return (
    <div style={styles.body}>
      <h4 id="alegere" style={styles.text}>
        Alegeti un personaj care va va însoți în aventura programării.
      </h4>
      <div id="copii" style={styles.copii}>
        {["copil1", "copil2", "copil3", "copil4", "copil5", "copil6"].map(
          (character) => (
            <img
              key={character}
              id={character}
              className="copil"
              src={`/img/copii/${character}.png`}
              alt={`Character ${character}`}
              style={{
                ...styles.characterImage,
                ...(selectedCharacter === character ? styles.border : {}),
              }}
              onClick={() => handleCharacterClick(character)}
            />
          )
        )}
      </div>
      <div id="nume" style={styles.text}>
        Introduceți numele dumneavoastră
      </div>
      <input
        id="intronume"
        type="text"
        name="nume"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={styles.input}
      />
      <div id="cod" style={styles.text}>
      Introduceți codul dumneavoastră de acces
      </div>
      <input
        id="introcod"
        type="text"
        name="cod"
        value={cod}
        onChange={(e) => setCod(e.target.value)}
        style={styles.input}
      />

      <button id="incepeJocul" onClick={handleStartGame} style={styles.button}>
        Începe jocul
      </button>
      <img src="/img/mi.png" id="logo" alt="Logo" style={styles.logo} />
    </div>
  );
};

const styles = {
  body: {
    overflowY: "hidden",
    position: "relative",
    background: "rgb(2,0,36)",
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(180,255,255,1) 0%, rgba(255,255,255,1) 100%)",
    textAlign: "center",
    padding: "20px",
  },
  logo: {
    maxWidth: "20%",
    maxHeight: "20%",
    display: "block",
    margin: "20px auto",
  },
  copii: {
    minWidth: "365px",
    margin: "10px auto",
  },
  characterImage: {
    display: "inline-block",
    width: "160px",
    height: "240px",
    cursor: "pointer",
    padding: "8px",
  },
  border: {
    border: "3px solid black",
    borderRadius: "10px",
  },
  text: {
    minWidth: "140px",
    margin: "5px auto",
    textAlign: "center",
  },
  input: {
    display: "block",
    margin: "5px auto",
    textAlign: "center",
    width: "140px",
  },
  button: {
    display: "block",
    width: "100px",
    margin: "20px auto",
    padding: "10px",
  }
};

export default CharacterSelectionApp;

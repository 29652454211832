import React, { useState, useEffect, useCallback } from 'react';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-chrome';

import JSCPP from 'JSCPP'; // Importăm JSCPP corect

const FreestyleCode = () => {
  const [editor, setEditor] = useState(null);
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');

  // Initialize the Ace Editor
  useEffect(() => {
    const editorInstance = ace.edit('editor');
    editorInstance.setTheme('ace/theme/chrome');
    editorInstance.session.setMode('ace/mode/c_cpp');
    setEditor(editorInstance);
    setCode("#include <stdio.h>\nint main(){\n  //codul tau \n  return 0;\n}");
    // Set initial code if needed
    editorInstance.setValue(code);
    editorInstance.clearSelection();
  }, [code]);

  // Function to run the code using JSCPP
  const handleRunCode = () => {
    try {
      // Clear previous output
      setOutput('');

      const myCode = editor.getSession().getValue(); // Get code from editor

      // JSCPP code execution
      const config = {
        stdio: {
          write: (outputPart) => {
            setOutput((prevOutput) => prevOutput + outputPart);
          },
        },
      };

      JSCPP.run(myCode, '', config);
    } catch (error) {
      setOutput(`Eroare la rularea codului: ${error.message}`);
    }
  };

  // Function to clear the code editor
  const newCode = useCallback(() => {
    setOutput('');
    if (editor) {
      editor.session.setValue('');
    }
  }, [editor]);

  return (
    <div style={styles.container}>
      <h2 id="title">Aici îti poți testa propriile programe</h2>
      <div id="editor" style={styles.editor}>
      </div>
      <button onClick={handleRunCode} style={styles.button}>
        Rulează Codul
      </button>
      <button onClick={newCode} style={styles.button}>
        Curata codul
      </button>
      <h5>Output:</h5>
      <div style={styles.output}>
        <pre>{output}</pre>
      </div>
    </div>
  );
};

const styles = {
  title: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    padding: '20px',
    margin: '0 auto',
    maxWidth: '800px'
  },
  button: {
    backgroundColor: 'rgb(0, 123, 255)',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#FFF',
    fontWeight: 'bold',
    margin: '10px',
  },
  editor: {
    margin: 0,
    maxWidth: '100%',
    height: '300px',
    marginTop: '20px',
    border: '2px solid #aaa',
    overflowY: 'scroll',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  output: {
    padding: '10px',
    border: '2px solid #aaa',
    backgroundColor: 'white',
    color: 'green',
    height: '250px',
    maxWidth: '100%',
    marginLeft: '40px',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
  },
  listitem:{
    listStyle: 'none',
    textAlign: 'left'
  }
};

export default FreestyleCode;

import React, { useState } from 'react';
import './LessonEditor.css';

const LessonEditor = () => {
  const initialLessons = [
    {
      "mainTitle": "",
      "title": "",
      "text": "",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/test.png",
      "image2": "/img/test.png",
      "codeSnippet": "\n      #include <iostream>\n      using namespace std;\n\n      int main() {\n        cout << \"Hello, world!\" << endl;\n        return 0;\n      }\n      ",
      "quiz": {
        "questions": [
          {
            "question": "",
            "options": [
              "",
              "",
              ""
            ],
            "answer": 0
          },
          {
            "question": "",
            "options": [
              "",
              "",
              ""
            ],
            "answer": 0
          }
        ]
      }
    }
  ];

  const [lessons, setLessons] = useState(initialLessons);
  const [selectedLesson, setSelectedLesson] = useState(0);

  const handleChange = (field, value, index) => {
    const updatedLessons = lessons.map((lesson, i) => {
      if (i === index) {
        return { ...lesson, [field]: value };
      }
      return lesson;
    });
    setLessons(updatedLessons);
  };

  const handleQuizChange = (questionIndex, field, value, lessonIndex, optionIndex = null) => {
    const updatedLessons = lessons.map((lesson, i) => {
      if (i === lessonIndex) {
        const updatedQuestions = lesson.quiz.questions.map((question, qIndex) => {
          if (qIndex === questionIndex) {
            if (field === 'options' && optionIndex !== null) {
              // Update the specific option
              const updatedOptions = question.options.map((option, oIndex) => {
                if (oIndex === optionIndex) {
                  return value;
                }
                return option;
              });
              return { ...question, options: updatedOptions };
            } else {
              // Update the question field (like question text or correct answer)
              return { ...question, [field]: value };
            }
          }
          return question;
        });
        return {
          ...lesson,
          quiz: { questions: updatedQuestions },
        };
      }
      return lesson;
    });
    setLessons(updatedLessons);
  };
  

  return (
    <div className="lesson-editor">
      <h1>Lesson Editor</h1>
      <div className="lesson-selector">
        <label>Select Lesson: </label>
        <select
          value={selectedLesson}
          onChange={(e) => setSelectedLesson(Number(e.target.value))}
        >
          {lessons.map((lesson, index) => (
            <option key={index} value={index}>
              {lesson.mainTitle}
            </option>
          ))}
        </select>
      </div>
      {lessons.map((lesson, index) => (
        <div
          key={index}
          className="lesson-form"
          style={{ display: index === selectedLesson ? 'block' : 'none' }}
        >
          <h2>Edit Lesson: {lesson.mainTitle}</h2>
          <div className="form-group">
            <label>Main Title</label>
            <input
              type="text"
              value={lesson.mainTitle}
              onChange={(e) => handleChange('mainTitle', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              value={lesson.title}
              onChange={(e) => handleChange('title', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Text</label>
            <textarea
              value={lesson.text}
              onChange={(e) => handleChange('text', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Title 2</label>
            <input
              type="text"
              value={lesson.title2}
              onChange={(e) => handleChange('title2', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Text 2</label>
            <textarea
              value={lesson.text2}
              onChange={(e) => handleChange('text2', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Title 3</label>
            <input
              type="text"
              value={lesson.title3}
              onChange={(e) => handleChange('title3', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Text 3</label>
            <textarea
              value={lesson.text3}
              onChange={(e) => handleChange('text3', e.target.value, index)}
            />
          </div>
          <div className="form-group">
            <label>Code Snippet</label>
            <textarea
              value={lesson.codeSnippet}
              onChange={(e) => handleChange('codeSnippet', e.target.value, index)}
            />
          </div>
          <h3>Quiz Questions</h3>
          {lesson.quiz.questions.map((question, qIndex) => (
            <div key={qIndex} className="quiz-question">
              <div className="form-group">
                <label>Question</label>
                <input
                  type="text"
                  value={question.question}
                  onChange={(e) => handleQuizChange(qIndex, 'question', e.target.value, index)}
                />
              </div>
              {question.options.map((option, oIndex) => (
  <div key={oIndex} className="form-group">
    <label>{`Option ${oIndex + 1}`}</label>
    <input
      type="text"
      value={option}
      onChange={(e) =>
        handleQuizChange(
          qIndex,
          'options',  // Field for the options
          e.target.value,  // New value
          index,  // Lesson index
          oIndex  // Option index
        )
      }
    />
  </div>
))}
              <div className="form-group">
                <label>Correct Answer</label>
                <select
                  value={question.answer}
                  onChange={(e) =>
                    handleQuizChange(qIndex, 'answer', Number(e.target.value), index)
                  }
                >
                  {question.options.map((option, oIndex) => (
                    <option key={oIndex} value={oIndex}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className="generated-code">
        <h2>Generated Code:</h2>
        <pre>{JSON.stringify(lessons, null, 2)}</pre>
      </div>
    </div>
  );
};

export default LessonEditor;
import React, { useState, useEffect, useCallback } from 'react';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-chrome';
import JSCPP from 'JSCPP';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';

const EditorPanel = ({ output, setOutput, currentLesson, lessonContent, showHighlights }) => {
  const [editor, setEditor] = useState(null);
  const [nume, setNume] = useState(() => {
    // Retrieve nume from local storage on initial render
    const nume = localStorage.getItem('nume');
    return nume ? nume : 'Student';
  });

  useEffect(() => {
    const editorInstance = ace.edit('editor');
    editorInstance.setTheme('ace/theme/chrome');
    editorInstance.session.setMode('ace/mode/c_cpp');
    setEditor(editorInstance);

    // Initialize with the new code and highlights
    newCode();
    highlight(5, 11, 1);
    highlight(1, 1, 2);
    highlight(2, 2, 3);
    highlight(0, 100, 0);
  }, []);

  const highlight = useCallback(
    (from, to, nr) => {
      if (editor) {
        const Range = ace.require('ace/range').Range;
        editor.session.addMarker(new Range(from, 0, to, 1), `myMarker${nr}`, 'fullLine');
      }
    },
    [editor]
  );

  const runCode = () => {
    setOutput(''); // Clear previous output
    const myCode = editor.getSession().getValue();
    const config = {
      stdio: {
        write: (s) => {
          setOutput((prevOutput) => prevOutput + s);
        },
      },
    };

    try {
      JSCPP.run(myCode, '', config);
    } catch (err) {
      setOutput(`Eroare deosebit de grava!\n${err}`);
    }
  };

  const newCode = useCallback(() => {
    setOutput('');
    if (editor) {
      editor.session.setValue('');
    }
  }, [editor]);

  const loadTest = () => {
    setOutput('');
    const testCode = lessonContent[currentLesson].codeSnippet;
    if (editor) {
      editor.session.setValue(testCode);
    }
  };

  return (
    <Container>
      <Card className="p-2">
        <Row className="mb-3">
          <Col xs={12}>
            <h3 className={showHighlights ? 'text-success' : 'd-none'}>
              Bună {nume}, aici poți scrie cod C!
            </h3>
          </Col>
        </Row>

        <Row>
          {/* Left Column for Buttons and Editor */}
          <Col xs={12} className="mb-3">
            <Button onClick={newCode} variant="primary" className="me-2 mb-3">
              Vreau sa rezolv
            </Button>
            <Button onClick={loadTest} variant="secondary" className="mb-3">
              Afiseaza Cod
            </Button>

            <pre id="editor" className="p-3 bg-light" style={styles.editor}></pre>

            <Button onClick={runCode} variant="primary" className="mt-3">
              Ruleaza Codul
            </Button>

            <h3 className={showHighlights ? 'text-success' : 'd-none'}>
              Bună {nume}, aici poți vizualiza ieșirile programelor
            </h3>

            <div className="bg-light p-3 mt-3" style={styles.output}>
              <div id="out_body">{output}</div>
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

const styles = {
  body: {
    overflowY: "hidden",
    position: "relative",
    background: "rgb(2,0,36)",
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(180,255,255,1) 0%, rgba(255,255,255,1) 100%)",
    textAlign: "center",
    padding: "20px",
  },
  editor: {
    margin: 0,
    width: '100%',
    height: '260px',
    border: '2px solid #aaa',
    overflowY: 'scroll',
  },
  output: {
    padding: '10px',
    border: '2px solid #aaa',
    color: 'var(--bs-nav-link-color)',
    height: '150px',
    width: '100%',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
  }
};

export default EditorPanel;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import {Card} from 'react-bootstrap';

const CanvasPanel = ({ lessonContent, currentLesson, showQuiz }) => {
    const images = {
        copil1: '/img/copii/copil1.png',
        copil2: '/img/copii/copil2.png',
        copil3: '/img/copii/copil3.png',
        copil4: '/img/copii/copil4.png',
        copil5: '/img/copii/copil5.png',
        copil6: '/img/copii/copil6.png',
        bg: '/img/bg_fara.png',
        bgc: '/img/bg_centuri.png',
        stea: '/img/stea.png',
        insigna: '/img/insigna.png',
    };

    const [copil, setCopil] = useState(() => {
        let copil = localStorage.getItem('copil');
        return images[copil] || images.copil1;
    });

    const [nume, setNume] = useState(() => {
        const nume = localStorage.getItem('nume');
        return nume ? nume : 'Student';
    });

    const canvasRef = useRef(null);

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
        });
    };

    const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
        const paragraphs = text.split('\n');
        let linesCount = 0;
        paragraphs.forEach(paragraph => {
            const words = paragraph.split(' ');
            let line = '';
            for (let n = 0; n < words.length; n++) {
                const testLine = line + words[n] + ' ';
                const testWidth = ctx.measureText(testLine).width;
                if (testWidth > maxWidth && n > 0) {
                    ctx.fillText(line, x, y);
                    line = words[n] + ' ';
                    y += lineHeight;
                    linesCount++;
                } else {
                    line = testLine;
                }
            }
            ctx.fillText(line, x, y);
            y += lineHeight;  // Move to the next line
            linesCount++;
        });
        return linesCount;
    };

    const updateCanvas = useCallback(
        async (ctx) => {
            const canvas = ctx.canvas;
            const scale = window.devicePixelRatio || 1;
            canvas.width = canvas.clientWidth * scale;
            canvas.height = canvas.clientHeight * scale;
            ctx.scale(scale, scale);

            const canvasWidth = canvas.clientWidth;
            const canvasHeight = canvas.clientHeight;
            const padding = 20;
            const lineHeight = 19;
            const textWidth = canvasWidth * 0.6;
            const imageWidth = canvasWidth * 0.3;
            const textXStart = padding;
            const imageXStart = canvasWidth - imageWidth - padding;
            const textYStart = 100;

            const item = lessonContent[currentLesson];

            const [img, img2, copilImg] = await Promise.all([
                loadImage(item.image).catch(() => null),
                loadImage(item.image2).catch(() => null),
                loadImage(copil).catch(() => null),
            ]);

            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            const gradient = ctx.createLinearGradient(0, 0, canvasWidth, 0);
            gradient.addColorStop(0, '#FFF');
            gradient.addColorStop(0.5, '#CCC');
            gradient.addColorStop(1, '#FFF');

            ctx.fillStyle = gradient;
            ctx.font = 'bold 1.4em Arial';
            ctx.shadowColor = 'rgba(0,0,0,0.3)';
            ctx.shadowOffsetX = 3;
            ctx.shadowOffsetY = 3;
            ctx.shadowBlur = 4;
            ctx.fillText(
                item.mainTitle,
                (canvasWidth / 2) - (ctx.measureText(item.mainTitle).width / 2),
                50
            );

            ctx.shadowColor = 'transparent';
            ctx.fillStyle = '#fff';
            ctx.font = 'bold 16px Arial';

            let yPosition = textYStart;

            ctx.fillText(item.title, textXStart, yPosition);
            yPosition += lineHeight + 10;

            ctx.font = '16px Arial';
            let firstTextLines = wrapText(ctx, item.text, textXStart, yPosition, textWidth, lineHeight);
            yPosition += (firstTextLines * lineHeight) + 10;


            if (canvas.getBoundingClientRect().width>390){

                ctx.font = 'bold 16px Arial';
                ctx.fillText(item.title2, textXStart, yPosition);
                yPosition += lineHeight + 10;

                ctx.font = '16px Arial';
                let secondTextLines = wrapText(ctx, item.text2, textXStart, yPosition, textWidth, lineHeight);
                yPosition += (secondTextLines * lineHeight) + 10;
                
            }
            if (canvas.getBoundingClientRect().width>590){
                
                ctx.font = 'bold 16px Arial';
                ctx.fillText(item.title3, textXStart, yPosition);
                yPosition += lineHeight + 10;

                ctx.font = '16px Arial';
                let thirdTextLines = wrapText(ctx, item.text3, textXStart, yPosition, textWidth, lineHeight);
                yPosition += (thirdTextLines * lineHeight) + 10;
            
            }

            if (canvas.getBoundingClientRect().width>390){
                if (img) {
                    const imageY = 80;
                    ctx.drawImage(img, imageXStart, imageY, imageWidth, imageWidth*0.95);
                }
            if (copilImg) {
                ctx.drawImage(copilImg, (canvasWidth / 2) + 150, 300, 120, 200);
            }
        }

        },
        [lessonContent, currentLesson, copil]
    );

    useEffect(() => {
        if (!showQuiz) {
            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    updateCanvas(ctx);
                }
            }
        }
    }, [currentLesson, showQuiz, updateCanvas]);

    return !showQuiz && <canvas style={styles.canvas} ref={canvasRef}></canvas>;
};

const styles = {
    canvas: {
        border: '2px solid #aaa',
        maxWidth: '900px',
        height: '520px',
        marginTop: '20px',
        marginLeft: '40px',
        background: 'linear-gradient(to bottom, #333, #666)',
    },
};

export default CanvasPanel;

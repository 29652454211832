// src/ThreeScene.js
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ThreeScene = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Add directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 10, 7.5);
    scene.add(directionalLight);

    // Create robot parts
    const createRobot = () => {
      // Materials
      const bodyMaterial = new THREE.MeshStandardMaterial({ color: 0x555555 });
      const limbMaterial = new THREE.MeshStandardMaterial({ color: 0x333333 });

      // Head
      const headGeometry = new THREE.BoxGeometry(1, 1, 1);
      const head = new THREE.Mesh(headGeometry, bodyMaterial);
      head.position.y = 2.5;

      // Body
      const bodyGeometry = new THREE.BoxGeometry(1.5, 2, 1);
      const body = new THREE.Mesh(bodyGeometry, bodyMaterial);

      // Arms
      const armGeometry = new THREE.BoxGeometry(0.3, 1, 0.3);
      const leftArm = new THREE.Mesh(armGeometry, limbMaterial);
      leftArm.position.set(-1, 0.5, 0);

      const rightArm = new THREE.Mesh(armGeometry, limbMaterial);
      rightArm.position.set(1, 0.5, 0);

      // Legs
      const legGeometry = new THREE.BoxGeometry(0.5, 1, 0.5);
      const leftLeg = new THREE.Mesh(legGeometry, limbMaterial);
      leftLeg.position.set(-0.5, -1.5, 0);

      const rightLeg = new THREE.Mesh(legGeometry, limbMaterial);
      rightLeg.position.set(0.5, -1.5, 0);

      // Assemble robot
      const robot = new THREE.Group();
      robot.add(head);
      robot.add(body);
      robot.add(leftArm);
      robot.add(rightArm);
      robot.add(leftLeg);
      robot.add(rightLeg);

      // Add robot to scene
      scene.add(robot);

      return robot;
    };

    const robot = createRobot();

    // Position the camera
    camera.position.set(0, 2, 5);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      robot.rotation.y += 0.01; // Rotate the robot for demonstration
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the scene
    return () => {
      window.removeEventListener('resize', handleResize);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} />;
};

export default ThreeScene;

import m from 'ace-builds/src-noconflict/mode-c_cpp';
import React, { useState, useEffect, useCallback } from 'react';

const Contact = () => {
  return (
    <div style={styles.container}>
      <div>
        <h2 style={styles.title}>Contactează-ne</h2>
        
        <p style={styles.title}>În plus față de lecțiile de pe acest website, ofer și meditații online personalizate la prețul de 80 de lei / oră. Pentru orice informație sau problemă legată de utilizarea website-ului, ne poți contacta folosind datele de mai jos:</p>
        
        <ul style={styles.contact}>
          <li style={styles.listitem}><strong>Email:  </strong> xcelent2005@yahoo.com</li>
          <li style={styles.listitem}>
              <strong>Telefon:  </strong> 
              <a href="tel:+40746250303" style={styles.link}>
                +40 746 25 03 03
              </a>
            </li>          
          <li style={styles.listitem}>
            <strong>WhatsApp:  </strong> 
            <a href="https://wa.me/40746250303" style={styles.link} target="_blank" rel="noopener noreferrer">
              +40 746 25 03 03
            </a>
          </li>          
          <li style={styles.listitem}>
            <strong>Telegram:  </strong> 
            <a href="https://t.me/ionutzdim" style={styles.link} target="_blank" rel="noopener noreferrer">
              @ionutzdim
            </a>
          </li>
        </ul>

      </div>
    </div>
  );
};

const styles = {
  title: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  button: {
    backgroundColor: 'rgb(0, 123, 255)',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#FFF',
    fontWeight: 'bold',
    margin: '10px',
  },
  editor: {
    margin: 0,
    width: '600px',
    height: '400px',
    marginTop: '20px',
    border: '2px solid #aaa',
    overflowY: 'scroll',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  output: {
    marginTop: '20px',
    backgroundColor: '#f1f1f1',
    padding: '10px',
    borderRadius: '5px',
    fontFamily: 'monospace',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  listitem:{
    listStyle: 'none',
    textAlign: 'left',
    padding: '10px',
  },
  justify:{
    textAlign: 'justify'
  },
  contact:{
    background:'linear-gradient(to bottom, #333, #666)',
    color: 'white',
    padding: '20px'
  },
  link:{
    color: 'white'
  }

};

export default Contact;

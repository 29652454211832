import React, { useState, useEffect, useCallback } from 'react';
import {Button} from 'react-bootstrap';

const Important = () => {

  return (
    <div style={styles.container}>

    <div style={styles.blueBox}>
      <p>Aplicația noastră oferă meditații la informatică pentru cei ce vor să învețe bazele limbajului C, astfel:</p>
      <ul>
        <li>5 lecții de programare gratuite sub formă de planșe și cod</li>
        <li>5 lecții de programare care pot fi achiziționate sub formă de planșe și cod</li>
        <li>ultima lecție conține 20 probleme de programare explicate</li>
        <li>un interpretor de C online cu care se pot scrie programe</li>
        <li>verificarea cunoștințelor prin quiz-uri</li>
      </ul>
      </div>
      <Button style={styles.title}><a style={styles.a} href="./">Vreau să învăț</a></Button>
      <div>

        <div style={styles.greenBox}>

          <p style={styles.justify}>Lecțiile noastre utilizează un compilator scris în JavaScript.</p>

          <p>Compilatorul nostru știe C de bază, adică se se pot face următoarele lucruri:</p>
          <ul>
            <li> poți declara variabile și scrie/comenta programe de bază</li>
            <li> poți afișa date la consolă</li>
            <li> poți folosi instrucțiuni de bază: if-else, for, while, do-while</li>
            <li> poți scrie și apela funcții</li>
            <li> poți lucra cu vectori</li>
            <li> poți lucra cu șiruri de caractere</li>
          </ul>
        </div>

        <div style={styles.redBox}>
          <p>Ce nu poți face cu compilatorul nostru:</p>
          <ul>
            <li> nu poți introduce/citi date de la consolă</li>
            <li> nu poți folosi înregistrări "typedef struct"</li>
            <li> nu poți folosi pointeri și alocare dinamică a memoriei</li>
            <li> nu poți folosi programare orientată pe obiecte și C++</li>
            <li> nu poți face inițializări de tablouri multidimensionale în bloc</li>
            <li> nu poti folosi functia strcmp pentru comparare de string-uri</li>
          </ul>
        </div>
      </div>

      <p style={styles.justify}>Compilatorul nostru este foarte simplu și este recomandat pentru începători.
        Dacă doriți să vă bucurați de o experiență completă în C, vă recomandăm să instalați pe calculator un compilator real, cum ar fi Code::Blocks cu MinGW sau Visual Studio.</p>

    </div>
  );
};

const styles = {
  a:{
    textDecoration: 'none',
    color: 'white'
  },
  title: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textDecoration: 'none',
    display: 'block',
    marginTop: '10px',
    marginBottom: '20px'
  },
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  button: {
    backgroundColor: 'rgb(0, 123, 255)',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#FFF',
    fontWeight: 'bold',
    margin: '10px',
  },
  editor: {
    margin: 0,
    width: '600px',
    height: '400px',
    marginTop: '20px',
    border: '2px solid #aaa',
    overflowY: 'scroll',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  output: {
    marginTop: '20px',
    backgroundColor: '#f1f1f1',
    padding: '10px',
    borderRadius: '5px',
    fontFamily: 'monospace',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  listitem: {
    listStyle: 'none',
    textAlign: 'left'
  },
  justify: {
    textAlign: 'justify'
  },
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  },
  blue: {
    color: 'rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))'
  },
  redBox: {
    backgroundColor: '#CC3333',
    color: 'white',
    padding: '20px',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '10px'
  },
  greenBox: {
    background: 'linear-gradient(rgb(51, 51, 51), rgb(102, 102, 102))',
    color: 'white',
    padding: '20px',
    borderRadius: '10px'
  },
  blueBox: {
    backgroundColor: 'rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))',
    color: 'white',
    padding: '20px',
    borderRadius: '10px'
  }

};

export default Important;

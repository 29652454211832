import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Importăm useNavigate pentru navigare
import Logo from './Logo';
import EditorPanel from './EditorPanel';
import CanvasPanel from './CanvasPanel';
import QuizPanel from './QuizPanel';
import LessonNavigation from './LessonNavigation';
import {Container,Card,Row,Col,Button} from 'react-bootstrap';


const EditorApp = () => {
  const [output, setOutput] = useState('');
  const [currentLesson, setCurrentLesson] = useState(0);
  const [showQuiz, setShowQuiz] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState({});
  const [showHighlights, setShowHighlights] = useState(false); // State pentru highlight-uri
  const { lessonSetId, lessonId } = useParams(); // Preluăm atât setul, cât și id-ul lecției din URL
  const navigate = useNavigate(); // Inițializăm useNavigate

  const [nume, setNume] = useState(() => {
    const nume = localStorage.getItem('nume');
    return nume ? nume : 'Student';
  });

  const lessonSetC1 = [
    {
      "mainTitle": "Cum să folosesc aplicația?",
      "title": "Salutare, " + nume + "!",
      "text": "În această lecție încercăm să explicăm modul de utilizare al aplicației.",
      "title2": "Primul meu program C",
      "text2": "Apasă pe butonul 'Afiseaza cod' pentru a afișa rezolvarea asociată acestei lecții. Apoi apasă butonul 'Rulează cod' și vezi ce se întâmplă.",
      "title3": "Cum să lucrez?",
      "text3": "Încearcă să memorezi codul, apoi apasă butonul 'Vreau să rezolv' și încearcă să scrii codul din memorie (fără copy-paste, te rog :). Apasă apoi din nou butonul 'Rulează cod'. Dacă programul e scris corect, ar trebui să afișeze 'Salut!' pe ecran.",
      "image": "/img/lectia11.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    printf(\"Salut!\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Această întrebare este de test. Selectează, te rog, 'Răspuns corect'!",
            "options": [
              "Răspuns greșit",
              "Răspuns greșit",
              "Răspuns corect"
            ],
            "answer": 2
          },
          {
            "question": "Încă o întrebare de test. Ce forma are soarele ?",
            "options": [
              "liniar",
              "pătrat",
              "rotund"
            ],
            "answer": 2
          }
        ]
      }
    },
    {
      "mainTitle": "Cum să folosesc aplicația?",
      "title": "Primul Tău Program în C: „Salut C!”",
      "text": "Apasă pe butonul 'Afiseaza cod' pentru a afișa rezolvarea asociată acestei lecții. În continuare, vom discuta ce face fiecare linie de cod.",
      "title2": "Ce se întâmplă în program?",
      "text2": "Linia '#include <stdio.h>' îi spune compilatorului că vom folosi librăria standard input-output (abreviat std.i.o). Librăria conține funcția printf (în limba engleză print înseamnă afișează) cu care putem să punem text pe ecran. Fiecare program C începe în funcția main și se execută de sus în jos și de la stânga la dreapta.",
      "title3": "Ce se întâmplă în funcția main?",
      "text3": "În cazul nostru, apelăm funcția printf prin care afișăm un text pe ecran „Salut!” urmat de caracterul linie nouă \\n, apoi apelăm funcția return prin care ieșim din program.",
      "image": "/img/lectia12.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    printf(\"Salut!\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția printf?",
            "options": [                     
              "Face ca programul să se termine.",
              "Afișează un text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția return?",
            "options": [
              "Face ca programul să se termine.",
              "Afișează un text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Cum să folosesc aplicația?",
      "title": "Primul meu program C",
      "text": "Să încercăm să afișăm pe ecran textul: \n„Salut! Îmi doresc să învăț limbajul C.”.\nTe invit să scrii în partea dreaptă, în căsuța de cod: „#include <stdio.h>”.\nÎn acest fel, vei încărca librăria STanDard InputOutput și vom avea acces ulterior la funcția printf.",
      "title2": "Să adăugăm funcția main",
      "text2": "Mai departe, sub textul „#include <stdio.h>”, să adăugăm funcția main.\nint main(){  \n//codul meu  \nreturn 0;\n}",
      "title3": "Să adăugăm mesajul afișat cu ajutorul funcției printf",
      "text3": "Mai departe, înlocuim „//codul meu” cu textul printf(„Salut! Îmi doresc să învăț limbajul C.”);\nApăsăm butonul Rulează Codul și, dacă totul a decurs cum trebuie, la output ar trebui să se afișeze „Salut! Îmi doresc să învăț limbajul C.”.",
      "image": "/img/lectia13.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    printf(\"Salut! Imi doresc sa invat limbajul C.\\n\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția printf?",
            "options": [
              "Face ca programul să se termine.",
              "Afișează un text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția return?",
            "options": [
              "Face ca programul să se termine.",
              "Afișează un text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    }
  ];
  const lessonSetC2 = [
    {
      "mainTitle": "Variabile și tipuri de date",
      "title": "Ce este un tip de date?",
      "text": "O să încep prin a da exemple de tipuri de date: întreg, real, șir de caractere.",
      "title2": "Un număr întreg este un număr fără virgulă.",
      "text2": "Tipul de date întreg descrie toate numerele întregi: … - 3, -2, -1, 0, 1,  2, 3, 4 …\nÎn C, tipul întreg se poate specifica prin cuvântul cheie int.\nExemplu: int n; // se declară o variabilă n de tip întreg",
      "title3": "Un număr real este un număr cu virgulă.",
      "text3": "Tipul de date real descrie toate numerele reale: -10.2, 0.001, 100.0, 1200.9\nÎn C, tipul real se poate specifica prin cuvântul cheie float.\nExemplu: float nr; // se declară o variabilă nr de tip real",
      "image": "/img/lectia21.png",
      "codeSnippet": "\t#include <stdio.h>\n\tint main() {\n\t\t// declaram variabila varsta avand tipul de date întreg\n\t\tint varsta;\n\t\tvarsta = 10;\n\t\tprintf(\"%d\",varsta);\n\t\treturn 0;\n\t}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date specifică int?",
            "options": [
              "tipul real",
              "tipul șir de caractere",
              "tipul întreg"
            ],
            "answer": 2
          },
          {
            "question": "Ce tip de date specifică float?",
            "options": [
              "tipul real",
              "tipul șir de caractere",
              "tipul întreg"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Variabile și tipuri de date",
      "title": "Ce este o variabilă?",
      "text": "O variabilă este un container pentru o valoare de un anumit tip de date.\nAm scris container pentru că în variabilă (care este un cuvânt sau o literă) putem să punem orice valoare care aparține tipului de date specificat.",
      "title2": "Exemplu de declarare de variabilă",
      "text2": "Când scriem int n; declarăm o variabilă de tip întreg cu numele n.\nÎn n putem să punem orice valoare de tip întreg, pentru că așa am „declarat”.",
      "title3": "Cum poate primi o variabilă o valoare?",
      "text3": "Pentru a-i atribui lui n valoarea 10, scriem n=10 (se citește n primește valoarea 10).\nA nu se confunda cu operatorul de egalitate (în acest caz n nu este egal cu 10, ci primește valoarea 10).",
      "image": "/img/lectia22.png",
      "codeSnippet": "\t#include <stdio.h>\n\tint main() {\n\t\t// declaram variabila varsta avand tipul de date întreg\n\t\tint varsta;\n\t\tvarsta = 10;\n\t\tprintf(\"%d\",varsta);\n\t\treturn 0;\n\t}",
      "quiz": {
        "questions": [
          {
            "question": "Ce este o variabilă?",
            "options": [
              "Un container pentru o valoare de un anumit tip.",
              "Un număr întreg cum ar fi 0,1,2.",
              "Un număr real cum ar fi PI, 0.123f sau 19.0f."
            ],
            "answer": 0
          },
          {
            "question": "De ce tipuri de date sunt variabilele: int a; float b;",
            "options": [
              "întreg, întreg",
              "întreg, real",
              "real, real"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Variabile și tipuri de date – Problema",
      "title": "Enunț problemă",
      "text": "Să se declare 2 variabile de tip întreg (int) a și b. Să se declare o variabilă de tip întreg (int) s.\nSă se seteze variabilele cu valorile 20 și 30. Să se calculeze în variabila s suma valorilor a și b.\nSă se afișeze valoarea sumei pe ecran.\nSă se scrie instrucțiunea „return 0;” pentru a ieși cu succes din program.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nDedesubt, scriem functia main: \n\tint main(){ \n\t\t// aici vom insera codul de mai jos\n\t\treturn 0;\n\t}\nDeclaram variabilele a si b de tip float: scriem float a; float b;\nDeclaram variabila s de tip float: scriem float s;\nSetam valorile variabilelor: a=20.0f;b=20.0f;\nCalculam suma, scriem: s=a+b;\nApoi afisam valoarea sumei pe ecran: printf(“%d”, s);",
      "title3": "",
      "text3": "",
      "image": "/img/lectia23.png",
      "codeSnippet": "      #include <stdio.h>\n\n      int main() {\n        float a;float b;\n        float s;\n        a=20.0f;\n        b=20.0f;\n        s=a+b;\n        printf(\"suma=\");\n        printf(\"%d\",s);\n        return 0;\n      }\n      ",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date specifică int?",
            "options": [
              "tipul real",
              "tipul șir de caractere",
              "tipul întreg"
            ],
            "answer": 2
          },
          {
            "question": "Ce tip de date specifică float?",
            "options": [
              "tipul real",
              "tipul șir de caractere",
              "tipul întreg"
            ],
            "answer": 0
          }
        ]
      }
    }
  ];
  const lessonSetC3 = [
    {
      "mainTitle": "Afișarea datelor pe ecran",
      "title": "Cum afișez date pe ecran?",
      "text": "Instrucțiunea cu care se afișează date pe ecran este printf.\nCel mai simplu exemplu de afișare de date pe ecran este printf(\"Salut!\");\nDupă printf, în paranteze, se poate specifica un șir de caractere care va fi afișat pe ecran.",
      "title2": "Cum afișez variabile pe ecran?",
      "text2": "În funcție de tipul de date al variabilei, se folosesc specificatori:\n%d: pentru a afișa numere întregi\n%f: pentru a afișa numere reale\n%c: pentru a afișa un caracter\n%s: pentru a afișa un text.",
      "title3": "Un exemplu de afișare a unei variabile de tip întreg este:",
      "text3": "int n; n=10; printf(\"%d\",n);",
      "image": "/img/lectia31.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int n;\n    n = 10;\n    printf(\"%d\", n);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este specificatorul pentru afișarea unui întreg (int)?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 0
          },
          {
            "question": "Care este specificatorul pentru afișarea unui număr real (float)?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Afișarea datelor pe ecran",
      "title": "Exemple de afișare a datelor pe ecran",
      "text": "Pentru a afișa o variabilă ce conține un număr întreg:\nint n; n=10; printf(\"%d\",n);\nPentru a afișa o variabilă ce conține un număr real:\nfloat n; n=10.0f; printf(\"%f\",n);",
      "title2": "Afișarea unui caracter",
      "text2": "char caracter; caracter='z'; printf(\"%c\",caracter);",
      "title3": "Afișarea unui text",
      "text3": "char text[100]; strcpy(text,\"Salut!\"); printf(\"%s\",text);",
      "image": "/img/lectia32.png",
"codeSnippet": "#include <stdio.h>\n#include <string.h>\nint main() {\n    // afisarea unui numar intreg pe ecran\n    int n;\n    n=10;\n    printf(\"%d\",n);\n    printf(\"\\n\");\n\n    // afisarea unui numar real pe ecran\n    float x;\n    x=10.0f;\n    printf(\"%f\",x);\n    printf(\"\\n\");\n\n    // afisarea unui caracter pe ecran\n    char caracter;\n    caracter='z';\n    printf(\"%c\",caracter);\n    printf(\"\\n\");\n\n    //afisarea unui sir de caractere pe ecran\n    char text[100];\n    strcpy(text, \"Salut!\");\n    printf(\"%s\", text);\n    printf(\"\\n\");\n    return 0;\n}",      "quiz": {
        "questions": [
          {
            "question": "Care este specificatorul pentru afișarea unui caracter?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 2
          },
          {
            "question": "Care este specificatorul pentru afișarea unui șir de caractere?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 3
          }
        ]
      }
    },
    {
      "mainTitle": "Afișarea datelor pe ecran – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare 2 variabile de tip real (float) a și b.\nSă se declare o variabilă de tip real (float) s.\nSă se seteze variabilele cu valorile 20.0f și 30.0f.\nSă se calculeze în variabila s suma valorilor a și b.\nSă se afișeze valoarea sumei pe ecran.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nDedesubt, scriem funcția main:\nint main(){\n    // aici vom insera codul de mai jos\n    return 0;\n}",
      "title3": "Idee de baza",
      "text3": "Declarăm variabilele a și b de tip float: scriem float a; float b;\nDeclarăm variabila s de tip float: scriem float s;\nSetăm valorile variabilelor: a=20.0f; b=30.0f;\nCalculăm suma, scriem: s=a+b;\nAfișăm valoarea sumei pe ecran: printf(\"%f\", s);",
      "image": "/img/lectia33.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    float a = 20.0f;\n    float b = 30.0f;\n    float s = a + b;\n    printf(\"%f\", s);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este specificatorul pentru afișarea unui număr real (float)?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 1
          },
          {
            "question": "Care este specificatorul pentru afișarea unui text?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 3
          }
        ]
      }
    }
  ];
  const lessonSetC4 = [
    {
      "mainTitle": "Operatori Aritmetici",
      "title": "Operatorii aritmetici",
      "text": "Operatorii aritmetici sunt + (plus), - (minus), / (împărțire), * (înmulțire), % (restul împărțirii).",
      "title2": "Operatorul pentru adunare",
      "text2": "Operatorul pentru adunare este + și se aplică pentru 2 operanzi.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = a + b;\nprintf(\"%d\", rezultat); // afișează valoarea 5",
      "title3": "Operatorul pentru scădere",
      "text3": "Operatorul pentru scădere este – și se aplică pentru 2 operanzi.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = a - b;\nprintf(\"%d\", rezultat); // afișează valoarea -1",
      "image": "/img/lectia41.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int a = 2;\n    int b = 3;\n    int rezultat = a + b;\n    printf(\"%d\", rezultat); // afișează 5\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este operatorul de scădere?",
            "options": [
              "+",
              "-",
              "/",
              "%"
            ],
            "answer": 1
          },
          {
            "question": "Câți operanzi are operatorul de scădere?",
            "options": [
              "1",
              "2",
              "3",
              "4"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori Aritmetici",
      "title": "Operatorul pentru înmulțire",
      "text": "Operatorul pentru înmulțire este * și se aplică pentru 2 operanzi.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = a * b;\nprintf(\"%d\", rezultat); // afișează valoarea 6",
      "title2": "Operatorul pentru împărțire",
      "text2": "Operatorul pentru împărțire este / și se aplică pentru 2 operanzi.\nExemplu:\nint a; int b; int rezultat;\na=6; b=3;\nrezultat = a / b;\nprintf(\"%d\", rezultat); // afișează valoarea 2",
      "title3": "",
      "text3": "",
      "image": "/img/lectia0.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int a = 11;\n    int b = 3;\n    int rezultat = a / b;\n    printf(\"%d\", rezultat); // afisează 3\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este operatorul de restul împărțirii?",
            "options": [
              "+",
              "-",
              "/",
              "%"
            ],
            "answer": 3
          },
          {
            "question": "Câți operanzi are operatorul de rest al împărțirii %?",
            "options": [
              "1",
              "2",
              "3",
              "4"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori Aritmetici",
      "title": "Operatorul pentru restul împărțirii",
      "text": "Operatorul pentru restul împărțirii este % și se aplică pentru 2 operanzi.\nExemplu:\nint a; int b; int rezultat;\na=11; b=3;\nrezultat = a % b;\nprintf(\"%d\", rezultat); // afișează 2",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia43.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int a = 11;\n    int b = 3;\n    int rezultat = a % b;\n    printf(\"%d\", rezultat); // afișează 2\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este operatorul pentru restul împărțirii?",
            "options": [
              "+",
              "-",
              "/",
              "%"
            ],
            "answer": 3
          },
          {
            "question": "Câți operanzi are operatorul de rest al împărțirii %?",
            "options": [
              "1",
              "2",
              "3",
              "4"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori de comparație",
      "title": "Ce fac operatorii de comparație?",
      "text": "Operatorii de comparație compară 2 valori și returnează rezultatul comparației.\nOperatorii de comparație sunt: <, <=, >, >=, ==, !=",
      "title2": "Operatorul > (mai mare)",
      "text2": "Operatorul “mai mare” este > și se aplică pentru 2 operanzi.\nRezultatul evaluării (op1 > op2) este 1 dacă op1 este mai mare decât op2 sau 0 dacă nu.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = a > b;\nprintf(\"%d\", rezultat); // afișează valoarea 0",
      "title3": "Operatorul < (mai mic)",
      "text3": "Operatorul “mai mic” este < și se aplică pentru 2 operanzi și lucrează similar operatorului >.",
      "image": "/img/lectia1.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 18;\n    if (varsta >= 18) {\n        printf(\"persoana este majora\");\n    } else {\n        printf(\"persoana nu este majora\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face operatorul >?",
            "options": [
              "Compară dacă prima valoare este mai mare decât a doua.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          },
          {
            "question": "Ce face operatorul <?",
            "options": [
              "Compară dacă prima valoare este mai mică decât a doua.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori de comparație",
      "title": "Operatorul >= (mai mare sau egal)",
      "text": "Operatorul “mai mare sau egal” este >= și se aplică pentru 2 operanzi.\nRezultatul evaluării (op1 >= op2) este 1 dacă op1 este mai mare sau egal cu op2.",
      "title2": "Operatorul <= (mai mic sau egal)",
      "text2": "Operatorul “mai mic sau egal” este <= și se aplică pentru 2 operanzi.\nRezultatul evaluării (op1 <= op2) este 1 dacă op1 este mai mic sau egal cu op2.\nExemplu:\nint a; int b; int rezultat;\na=3; b=3;\nrezultat = a <= b;\nprintf(\"%d\", rezultat); // afișează valoarea 1",
      "title3": "",
      "text3": "",
      "image": "/img/lectia1.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int kilometraj = 2000;\n    if (kilometraj > 100000) {\n        printf(\"trebuie sa faci revizia la masina\");\n    } else {\n        printf(\"inca nu e nevoie de revizie la masina\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face operatorul >=?",
            "options": [
              "Compară dacă prima valoare este mai mare sau egală cu a doua.",
              "Compară dacă prima valoare este mai mică decât a doua.",
              "Nu face nimic."
            ],
            "answer": 0
          },
          {
            "question": "Ce face operatorul <=?",
            "options": [
              "Compară dacă prima valoare este mai mică sau egală cu a doua.",
              "Compară dacă prima valoare este mai mare decât a doua.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori de comparație",
      "title": "Operatorii == (egal) și != (diferit)",
      "text": "Operatorul de egalitate == verifică dacă 2 valori sunt egale.\nOperatorul diferit != verifică dacă 2 valori sunt distincte.",
      "title2": "Operatorul de egalitate",
      "text2": "Operatorul de egalitate este == și se aplică pentru 2 operanzi.\nRezultatul evaluării (op1 == op2) este 1 dacă op1 este egal cu op2.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = (a == b);\nprintf(\"%d\", rezultat); // afișează valoarea 0",
      "title3": "Operatorul diferit",
      "text3": "Operatorul diferit este != și se aplică pentru 2 operanzi.\nRezultatul evaluării (op1 != op2) este 1 dacă op1 este diferit de op2.\nExemplu:\nint a; int b; int rezultat;\na=2; b=3;\nrezultat = (a != b);\nprintf(\"%d\", rezultat); // afișează valoarea 1",
      "image": "/img/lectia0.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 18;\n    if (varsta == 18) {\n        printf(\"vârsta este egală cu 18 ani\");\n    } else {\n        printf(\"vârsta nu este egală cu 18 ani\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face operatorul ==?",
            "options": [
              "Compară dacă două valori sunt egale.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          },
          {
            "question": "Ce face operatorul !=?",
            "options": [
              "Compară dacă două valori sunt diferite.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operatorul de atribuire",
      "title": "Operatorul =",
      "text": "Operatorul de atribuire atribuie/seteaza valoarea unei variabile.",
      "title2": "Mod de actiune",
      "text2": "Variabila din partea stanga ia valoarea expresiei din partea dreapta.",
      "title3": "n = 10 - 1",
      "text3": "Se citeste variabilei n i se atribuie valoarea expresiei 10 - 1. Adica n primeste valoarea 9.",
      "image": "/img/lectia47.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n int n;\n n = 10-1;\n printf(\"%d\",n);\n return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face operatorul ==?",
            "options": [
              "Compară dacă două valori sunt egale.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          },
          {
            "question": "Ce face operatorul !=?",
            "options": [
              "Compară dacă două valori sunt diferite.",
              "Afișează text pe ecran.",
              "Nu face nimic."
            ],
            "answer": 0
          }
        ]
      }
    }
  ];
  const lessonSetC5 = [
    {
      "mainTitle": "Instrucțiunea if în C",
      "title": "Ce este instrucțiunea if?",
      "text": "Instrucțiunea `if` în C este folosită pentru a executa o bucată de cod doar dacă o anumită condiție este adevărată. Este un element de bază al structurilor de control și permite luarea de decizii în funcție de anumite condiții.",
      "title2": "Sintaxa instrucțiunii if",
      "text2": "Sintaxa pentru instrucțiunea `if` este următoarea:\n \nif (condiție) {\n    // codul care va fi executat dacă condiția este adevărată\n}\n \nDacă condiția este adevărată (evaluată ca `true`), codul din interiorul acoladelor va fi executat.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia51.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 20;\n    if (varsta >= 18) {\n        printf(\"Persoana este majora\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea if?",
            "options": [
              "Execută codul indiferent de condiție.",
              "Execută codul doar dacă condiția este adevărată.",
              "Execută codul doar dacă condiția este falsă."
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă dacă condiția din if este falsă?",
            "options": [
              "Codul din interiorul blocului if va fi ignorat.",
              "Codul din interiorul blocului if va fi executat.",
              "Programul se oprește."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea if în C",
      "title": "Exemplu simplu",
      "text": "Să presupunem că dorim să verificăm dacă un număr este mai mare decât 10:\n \n#include <stdio.h>\nint main() {\n    int numar = 15;\n    if (numar > 10) {\n        printf(\"Numărul este mai mare decât 10\\n\");\n    }\n    return 0;\n}\n \nÎn acest exemplu, deoarece variabila `numar` este mai mare decât 10, mesajul \"Numărul este mai mare decât 10\" va fi afișat pe ecran.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia52.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 20;\n    if (varsta >= 18) {\n        printf(\"Persoana este majora\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea if?",
            "options": [
              "Execută codul indiferent de condiție.",
              "Execută codul doar dacă condiția este adevărată.",
              "Execută codul doar dacă condiția este falsă."
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă dacă condiția din if este falsă?",
            "options": [
              "Codul din interiorul blocului if va fi ignorat.",
              "Codul din interiorul blocului if va fi executat.",
              "Programul se oprește."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea if în C",
      "title": "Ce este instrucțiunea if-else?",
      "text": "Instrucțiunea `if-else` extinde funcționalitatea lui `if`. În cazul în care condiția din `if` este falsă, instrucțiunea `else` permite execuția unui alt bloc de cod.",
      "title2": "Sintaxa instrucțiunii if-else",
      "text2": "Sintaxa pentru `if-else` este:\n \nif (condiție) {\n    // codul executat dacă condiția este adevărată\n} else {\n    // codul executat dacă condiția este falsă\n}\n \nÎn acest caz, unul dintre cele două blocuri de cod va fi executat, în funcție de rezultatul condiției.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia53.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 16;\n    if (varsta >= 18) {\n        printf(\"Persoana este majora\\n\");\n    } else {\n        printf(\"Persoana nu este majora\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea else?",
            "options": [
              "Execută codul dacă condiția din if este adevărată.",
              "Execută codul dacă condiția din if este falsă.",
              "Ignoră codul."
            ],
            "answer": 1
          },
          {
            "question": "Cum aflăm dacă o persoană este majoră?",
            "options": [
              "Comparăm dacă vârsta este mai mare sau egală cu 18.",
              "Comparăm dacă vârsta este mai mică decât 18.",
              "Executăm codul fără condiție."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea if în C",
      "title": "Exemplu de if-else",
      "text": "Să presupunem că dorim să verificăm dacă o persoană este majoră:\n \n#include <stdio.h>\nint main() {\n    int varsta = 16;\n    if (varsta >= 18) {\n        printf(\"Persoana este majora\\n\");\n    } else {\n        printf(\"Persoana nu este majora\\n\");\n    }\n    return 0;\n}\n \nÎn acest caz, deoarece `varsta` este mai mică decât 18, mesajul \"Persoana nu este majoră\" va fi afișat.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia54.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int varsta = 16;\n    if (varsta >= 18) {\n        printf(\"Persoana este majora\\n\");\n    } else {\n        printf(\"Persoana nu este majora\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea else?",
            "options": [
              "Execută codul dacă condiția din if este adevărată.",
              "Execută codul dacă condiția din if este falsă.",
              "Ignoră codul."
            ],
            "answer": 1
          },
          {
            "question": "Cum aflăm dacă o persoană este majoră?",
            "options": [
              "Comparăm dacă vârsta este mai mare sau egală cu 18.",
              "Comparăm dacă vârsta este mai mică decât 18.",
              "Executăm codul fără condiție."
            ],
            "answer": 0
          }
        ]
      }
    }
  ];
  const lessonSetC6 = [
    {
      "mainTitle": "Instrucțiunea while în C",
      "title": "Ce este instrucțiunea while?",
      "text": "Instrucțiunea `while` în C este folosită pentru a repeta execuția unui bloc de cod atâta timp cât o condiție specificată este adevărată. Aceasta este utilă atunci când nu știm dinainte de câte ori va trebui să repetăm o acțiune.",
      "title2": "Sintaxa instrucțiunii while",
      "text2": "Sintaxa pentru instrucțiunea `while` este:\n \nwhile (condiție) {\n    // codul care va fi executat atâta timp cât condiția este adevărată\n}\n \nBlocul de cod din interiorul instrucțiunii `while` va fi executat repetitiv, până când condiția devine falsă.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia61.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int i = 1;\n    while (i <= 5) {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea while?",
            "options": [
              "Execută codul o singură dată.",
              "Execută codul repetitiv cât timp condiția este adevărată.",
              "Execută codul dacă condiția este falsă."
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă când condiția din while devine falsă?",
            "options": [
              "Bucla continuă să ruleze.",
              "Bucla se oprește și codul următor este executat.",
              "Programul se oprește."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea while în C",
      "title": "Exemplu de instrucțiune while",
      "text": "Să presupunem că dorim să afișăm pe ecran numerele de la 1 la 5:\n \n#include <stdio.h>\nint main() {\n    int i = 1;\n    while (i <= 5) {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    }\n    return 0;\n}\n \nÎn acest exemplu, condiția `i <= 5` este verificată la fiecare iterație, iar valoarea lui `i` crește cu 1 până când condiția devine falsă.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia62.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int i = 1;\n    while (i <= 5) {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face instrucțiunea while?",
            "options": [
              "Execută codul o singură dată.",
              "Execută codul repetitiv cât timp condiția este adevărată.",
              "Execută codul dacă condiția este falsă."
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă când condiția din while devine falsă?",
            "options": [
              "Bucla continuă să ruleze.",
              "Bucla se oprește și codul următor este executat.",
              "Programul se oprește."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea do-while în C",
      "title": "Ce este instrucțiunea do-while?",
      "text": "Instrucțiunea `do-while` este similară cu `while`, dar cu o diferență importantă: blocul de cod va fi executat cel puțin o dată, chiar dacă condiția este falsă de la început. După prima execuție, condiția este verificată, iar dacă este adevărată, bucla continuă.",
      "title2": "Sintaxa instrucțiunii do-while",
      "text2": "Sintaxa pentru `do-while` este:\n \ndo {\n    // codul care va fi executat\n} while (condiție);\n \nBlocul de cod este executat o dată, apoi condiția este verificată. În cazul în care condiția este adevărată, bucla continuă.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia63.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int i = 1;\n    do {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    } while (i <= 5);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce diferențiază instrucțiunea do-while de while?",
            "options": [
              "Bucla se execută doar dacă condiția este adevărată de la început.",
              "Bucla se execută cel puțin o dată, indiferent de condiție.",
              "Bucla nu verifică condiția."
            ],
            "answer": 1
          },
          {
            "question": "Ce face bucla do-while dacă condiția este falsă?",
            "options": [
              "Execută blocul de cod o singură dată și apoi se oprește.",
              "Execută blocul de cod la nesfârșit.",
              "Nu execută codul."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea do-while în C",
      "title": "Exemplu de instrucțiune do-while",
      "text": "Să presupunem că dorim să afișăm numerele de la 1 la 5 folosind `do-while`:\n \n#include <stdio.h>\nint main() {\n    int i = 1;\n    do {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    } while (i <= 5);\n    return 0;\n}\n \nÎn acest exemplu, chiar dacă `i` ar fi fost inițializat cu o valoare mai mare decât 5, bucla ar fi executat o iterație înainte de a verifica condiția.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia64.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int i = 1;\n    do {\n        printf(\"%d\\n\", i);\n        i=i+1;\n    } while (i <= 5);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce diferențiază instrucțiunea do-while de while?",
            "options": [
              "Bucla se execută doar dacă condiția este adevărată de la început.",
              "Bucla se execută cel puțin o dată, indiferent de condiție.",
              "Bucla nu verifică condiția."
            ],
            "answer": 1
          },
          {
            "question": "Ce face bucla do-while dacă condiția este falsă?",
            "options": [
              "Execută blocul de cod o singură dată și apoi se oprește.",
              "Execută blocul de cod la nesfârșit.",
              "Nu execută codul."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea for în C",
      "title": "Ce este instrucțiunea for?",
      "text": "Instrucțiunea `for` este folosită pentru a repeta un bloc de cod de un număr bine definit de ori. Aceasta este utilă când știm de la început de câte ori vrem să executăm codul. Bucla `for` permite inițializarea unei variabile, verificarea unei condiții și modificarea acelei variabile în cadrul aceleiași sintaxe.",
      "title2": "Sintaxa instrucțiunii for",
      "text2": "Sintaxa pentru `for` este:\n \nfor (inițializare; condiție; incrementare) {\n    // codul care va fi executat\n}\n \nLa început, variabila este inițializată, condiția este verificată la fiecare iterație, iar după fiecare execuție, variabila este incrementată sau modificată.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia65.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    for (int i = 1; i <= 5; i=i+1) {\n        printf(\"%d\\n\", i);\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Când este folosită instrucțiunea for?",
            "options": [
              "Când nu știm de câte ori trebuie să repetăm codul.",
              "Când știm dinainte de câte ori trebuie să repetăm codul.",
              "Când nu vrem să repetăm codul."
            ],
            "answer": 1
          },
          {
            "question": "Ce face ultima parte din sintaxa for?",
            "options": [
              "Inițializează variabila de control.",
              "Verifică condiția de continuare a buclei.",
              "Modifică variabila de control după fiecare iterație."
            ],
            "answer": 2
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea for în C",
      "title": "Exemplu de instrucțiune for",
      "text": "Să presupunem că dorim să afișăm numerele de la 1 la 5 folosind `for`:\n \n#include <stdio.h>\nint main() {\n    for (int i = 1; i <= 5; i=i+1) {\n        printf(\"%d\\n\", i);\n    }\n    return 0;\n}\n \nÎn acest exemplu, variabila `i` este inițializată la 1, condiția `i <= 5` este verificată la fiecare iterație, iar `i` crește cu 1 după fiecare execuție.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia66.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    for (int i = 1; i <= 5; i=i+1) {\n        printf(\"%d\\n\", i);\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Când este folosită instrucțiunea for?",
            "options": [
              "Când nu știm de câte ori trebuie să repetăm codul.",
              "Când știm dinainte de câte ori trebuie să repetăm codul.",
              "Când nu vrem să repetăm codul."
            ],
            "answer": 1
          },
          {
            "question": "Ce face ultima parte din sintaxa for?",
            "options": [
              "Inițializează variabila de control.",
              "Verifică condiția de continuare a buclei.",
              "Modifică variabila de control după fiecare iterație."
            ],
            "answer": 2
          }
        ]
      }
    }
  ];
  const lessonSetC7 = [
    {
      "mainTitle": "Ce este o funcție în C?",
      "title": "Definiția unei funcții",
      "text": "În limbajul C, o funcție este un bloc de cod reutilizabil care execută o anumită sarcină. Funcțiile sunt utile pentru a organiza codul, a-l face mai ușor de înțeles și de gestionat, precum și pentru a evita repetarea aceleiași bucăți de cod în mai multe locuri.\nFuncțiile pot primi argumente, pot returna valori și sunt definite o singură dată, dar pot fi apelate de oriunde în program.",
      "title2": "Sintaxa unei funcții",
      "text2": "Sintaxa unei funcții în C este următoarea:\n \ntip_return funcție_nume(parametri) {\n    // corpul funcției\n    return valoare; // opțional, dacă funcția returnează ceva\n}\n \n- `tip_return` specifică tipul valorii returnate (de exemplu, `int`, `float`, `void`).\n- `funcție_nume` este numele funcției.\n- `parametri` sunt valorile de intrare pentru funcție.\n- `return` returnează o valoare, dacă este necesar.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia71.png",
      "codeSnippet": "#include <stdio.h>\nint patrat(int n) {\n    return n * n;\n}\nint main() {\n    int rezultat = patrat(5);\n    printf(\"Patratul lui 5 este: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce este o funcție în limbajul C?",
            "options": [
              "Un bloc de cod care este executat o singură dată.",
              "Un bloc de cod reutilizabil care execută o anumită sarcină.",
              "O variabilă care stochează date."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția return?",
            "options": [
              "Returnează o valoare din funcție la apelant.",
              "Afișează date pe ecran.",
              "Închide programul."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Ce este o funcție în C?",
      "title": "Exemplu de funcție simplă",
      "text": "Să creăm o funcție care calculează pătratul unui număr întreg:\n \n#include <stdio.h>\nint patrat(int n) {\n    return n * n;\n}\nint main() {\n    int rezultat = patrat(5);\n    printf(\"Patratul lui 5 este: %d\\n\", rezultat);\n    return 0;\n}\n \nÎn acest exemplu, funcția `patrat` primește un argument `n`, îl înmulțește cu el însuși și returnează rezultatul.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia72.png",
      "codeSnippet": "#include <stdio.h>\nint patrat(int n) {\n    return n * n;\n}\nint main() {\n    int rezultat = patrat(5);\n    printf(\"Patratul lui 5 este: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce este o funcție în limbajul C?",
            "options": [
              "Un bloc de cod care este executat o singură dată.",
              "Un bloc de cod reutilizabil care execută o anumită sarcină.",
              "O variabilă care stochează date."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția return?",
            "options": [
              "Returnează o valoare din funcție la apelant.",
              "Afișează date pe ecran.",
              "Închide programul."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții care nu returnează nimic",
      "title": "Funcții de tip void",
      "text": "Funcțiile care nu returnează o valoare au tipul de returnare `void`. Aceste funcții execută o acțiune și nu întorc niciun rezultat apelantului.",
      "title2": "Sintaxa unei funcții de tip void",
      "text2": "Sintaxa este similară cu orice altă funcție, dar cu `void` în loc de un tip de returnare:\n \nvoid funcție_nume(parametri) {\n    // codul care se execută\n}\n \nAceste funcții nu includ instrucțiunea `return`, deoarece nu returnează nicio valoare.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia73.png",
      "codeSnippet": "#include <stdio.h>\nvoid afisareMesaj() {\n    printf(\"Aceasta este o functie de tip void\\n\");\n}\nint main() {\n    afisareMesaj();\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce înseamnă o funcție de tip void?",
            "options": [
              "Funcția returnează un număr întreg.",
              "Funcția returnează un șir de caractere.",
              "Funcția nu returnează nicio valoare."
            ],
            "answer": 2
          },
          {
            "question": "Funcțiile de tip void pot avea parametri?",
            "options": [
              "Da.",
              "Nu.",
              "Doar dacă returnează un int."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții care nu returnează nimic",
      "title": "Exemplu de funcție care nu returnează nimic",
      "text": "Să creăm o funcție care afișează un mesaj, dar nu returnează nimic:\n \n#include <stdio.h>\nvoid afisareMesaj() {\n    printf(\"Aceasta este o functie de tip void\\n\");\n}\nint main() {\n    afisareMesaj();\n    return 0;\n}\n \nÎn acest exemplu, funcția `afisareMesaj` nu returnează nicio valoare, dar afișează un text pe ecran.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia74.png",
      "codeSnippet": "#include <stdio.h>\nvoid afisareMesaj() {\n    printf(\"Aceasta este o functie de tip void\\n\");\n}\nint main() {\n    afisareMesaj();\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce înseamnă o funcție de tip void?",
            "options": [
              "Funcția returnează un număr întreg.",
              "Funcția returnează un șir de caractere.",
              "Funcția nu returnează nicio valoare."
            ],
            "answer": 2
          },
          {
            "question": "Funcțiile de tip void pot avea parametri?",
            "options": [
              "Da.",
              "Nu.",
              "Doar dacă returnează un int."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții care returnează valori",
      "title": "Funcții cu tip de returnare",
      "text": "Funcțiile care returnează valori trebuie să specifice tipul de date returnat, cum ar fi `int`, `float` sau `char`. Aceste funcții execută o operație și întorc rezultatul către apelant.",
      "title2": "Sintaxa funcțiilor care returnează valori",
      "text2": "Sintaxa este următoarea:\n \ntip_return funcție_nume(parametri) {\n    // codul care se execută\n    return valoare;\n}\n \n- `tip_return` specifică tipul valorii returnate.\n- `return valoare` întoarce rezultatul către funcția care a apelat funcția curentă.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia75.png",
      "codeSnippet": "#include <stdio.h>\nint suma(int a, int b) {\n    return a + b;\n}\nint main() {\n    int rezultat = suma(10, 5);\n    printf(\"Suma este: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date trebuie specificat pentru o funcție care returnează o valoare?",
            "options": [
              "void",
              "Un tip de date ca `int` sau `float`.",
              "Nicio specificație."
            ],
            "answer": 1
          },
          {
            "question": "Ce face instrucțiunea return?",
            "options": [
              "Oprește programul.",
              "Returnează o valoare funcției apelante.",
              "Afișează date pe ecran."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții care returnează valori",
      "title": "Exemplu de funcție care returnează o valoare",
      "text": "Să creăm o funcție care calculează suma a două numere și returnează rezultatul:\n \n#include <stdio.h>\nint suma(int a, int b) {\n    return a + b;\n}\nint main() {\n    int rezultat = suma(10, 5);\n    printf(\"Suma este: %d\\n\", rezultat);\n    return 0;\n}\n \nÎn acest exemplu, funcția `suma` primește două argumente, le adună și returnează rezultatul.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia77.png",
      "codeSnippet": "#include <stdio.h>\nint suma(int a, int b) {\n    return a + b;\n}\nint main() {\n    int rezultat = suma(10, 5);\n    printf(\"Suma este: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date trebuie specificat pentru o funcție care returnează o valoare?",
            "options": [
              "void",
              "Un tip de date ca `int` sau `float`.",
              "Nicio specificație."
            ],
            "answer": 1
          },
          {
            "question": "Ce face instrucțiunea return?",
            "options": [
              "Oprește programul.",
              "Returnează o valoare funcției apelante.",
              "Afișează date pe ecran."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții ale limbajului C",
      "title": "Ce sunt funcțiile standard?",
      "text": "Limbajul C oferă un set de funcții predefinite, cunoscute ca funcții standard. Acestea sunt incluse în bibliotecile standard ale limbajului și pot fi utilizate pentru sarcini comune, cum ar fi manipularea șirurilor de caractere, efectuarea de operații matematice sau lucrul cu fișiere.",
      "title2": "Funcții din biblioteca standard",
      "text2": "Câteva exemple de funcții din biblioteca standard C:\n- `printf()`: Afișează text pe ecran.\n- `scanf()`: Citește valori de la tastatură.\n- `strlen()`: Calculează lungimea unui șir de caractere.\n- `sqrt()`: Calculează radicalul unui număr.\nFuncțiile standard sunt accesibile prin includerea bibliotecilor potrivite, cum ar fi `<stdio.h>` sau `<math.h>`.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia78.png",
      "codeSnippet": "#include <stdio.h>\n#include <math.h>\nint main() {\n    double numar = 16.0;\n    double rezultat = sqrt(numar);\n    printf(\"Radicalul lui %.2f este %.2f\\n\", numar, rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția printf()?",
            "options": [
              "Afișează text pe ecran.",
              "Citește date de la tastatură.",
              "Calculează radicalul unui număr."
            ],
            "answer": 0
          },
          {
            "question": "Ce trebuie inclus pentru a folosi funcții matematice ca sqrt()?",
            "options": [
              "#include <stdio.h>",
              "#include <math.h>",
              "#include <string.h>"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Funcții ale limbajului C",
      "title": "Exemplu de utilizare a unei funcții standard",
      "text": "Să folosim funcția `sqrt` pentru a calcula radicalul unui număr:\n \n#include <stdio.h>\n#include <math.h>\nint main() {\n    double numar = 16.0;\n    double rezultat = sqrt(numar);\n    printf(\"Radicalul lui %.2f este %.2f\\n\", numar, rezultat);\n    return 0;\n}\n \nÎn acest exemplu, funcția `sqrt` din biblioteca `math.h` este folosită pentru a calcula radicalul unui număr.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia79.png",
      "codeSnippet": "#include <stdio.h>\n#include <math.h>\nint main() {\n    double numar = 16.0;\n    double rezultat = sqrt(numar);\n    printf(\"Radicalul lui %.2f este %.2f\\n\", numar, rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția printf()?",
            "options": [
              "Afișează text pe ecran.",
              "Citește date de la tastatură.",
              "Calculează radicalul unui număr."
            ],
            "answer": 0
          },
          {
            "question": "Ce trebuie inclus pentru a folosi funcții matematice ca sqrt()?",
            "options": [
              "#include <stdio.h>",
              "#include <math.h>",
              "#include <string.h>"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Funcția main în C",
      "title": "Ce este funcția main?",
      "text": "Funcția `main` este punctul de intrare în orice program C. Această funcție este obligatorie și reprezintă locul de unde începe execuția programului. Fiecare program C trebuie să conțină o funcție `main`, chiar dacă este o aplicație foarte simplă.",
      "title2": "Sintaxa funcției main",
      "text2": "Sintaxa tipică pentru funcția `main` este:\n \nint main() {\n    // codul programului\n    return 0;\n}\n \n- `int` indică faptul că funcția returnează o valoare întreagă, de obicei `0` pentru a indica că programul a rulat cu succes.\n- Codul programului este plasat în interiorul acoladelor.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia710.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    printf(\"Salut, lume!\\n\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția main?",
            "options": [
              "Este punctul de intrare în programul C.",
              "Afișează text pe ecran.",
              "Calculează valori."
            ],
            "answer": 0
          },
          {
            "question": "Ce valoare returnează de obicei funcția main?",
            "options": [
              "1 pentru succes.",
              "0 pentru succes.",
              "Nicio valoare."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Funcția main în C",
      "title": "Exemplu de funcție main",
      "text": "Iată un exemplu simplu de program care folosește funcția `main` pentru a afișa un mesaj:\n \n#include <stdio.h>\nint main() {\n    printf(\"Salut, lume!\\n\");\n    return 0;\n}\n \nÎn acest exemplu, funcția `main` afișează textul \"Salut, lume!\" și returnează `0` pentru a indica că programul s-a terminat cu succes.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia711.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    printf(\"Salut, lume!\\n\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face funcția main?",
            "options": [
              "Este punctul de intrare în programul C.",
              "Afișează text pe ecran.",
              "Calculează valori."
            ],
            "answer": 0
          },
          {
            "question": "Ce valoare returnează de obicei funcția main?",
            "options": [
              "1 pentru succes.",
              "0 pentru succes.",
              "Nicio valoare."
            ],
            "answer": 1
          }
        ]
      }
    }
  ];
  const lessonSetC8 = [
    {
      "mainTitle": "Șiruri de întregi (vectori) în C",
      "title": "Ce este un vector de întregi?",
      "text": "Un vector în limbajul C este o structură de date care poate stoca mai multe valori de același tip, toate la locații de memorie consecutive. În cazul unui vector de întregi, toate valorile stocate în acest vector vor fi de tip `int`.",
      "title2": "Cum declarăm un vector de întregi?",
      "text2": "Pentru a declara un vector de întregi, folosim sintaxa:\n \nint numere[5];\n \nAcest exemplu declară un vector `numere` care poate stoca 5 valori întregi. Fiecare element al vectorului este accesibil folosind un index, iar indexarea începe de la 0.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia81.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5]; // declarare vector de intregi cu 5 elemente\n    numere[0] = 10;\n    numere[1] = 20;\n    numere[2] = 30;\n    numere[3] = 40;\n    numere[4] = 50;\n\n    printf(\"Primul element: %d\\n\", numere[0]);\n    printf(\"Ultimul element: %d\\n\", numere[4]);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum declarăm un vector de întregi cu 10 elemente?",
            "options": [
              "int numere;",
              "int numere[10];",
              "float numere[10];"
            ],
            "answer": 1
          },
          {
            "question": "Care este indexul primului element dintr-un vector?",
            "options": [
              "0",
              "1",
              "10"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Șiruri de întregi (vectori) în C",
      "title": "Accesarea și modificarea elementelor unui vector de întregi",
      "text": "Pentru a accesa un element din vector, folosim numele vectorului urmat de indicele elementului respectiv, în paranteze pătrate. Exemplu:\n \nnumere[0] = 10; // atribuim valoarea 10 primului element din vector\nprintf(\"Primul element este: %d\\n\", numere[0]); // afișăm primul element\n \nIndicele 0 reprezintă primul element al vectorului.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia82.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5]; // declarare vector de intregi cu 5 elemente\n    numere[0] = 10;\n    numere[1] = 20;\n    numere[2] = 30;\n    numere[3] = 40;\n    numere[4] = 50;\n\n    printf(\"Primul element: %d\\n\", numere[0]);\n    printf(\"Ultimul element: %d\\n\", numere[4]);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum declarăm un vector de întregi cu 10 elemente?",
            "options": [
              "int numere;",
              "int numere[10];",
              "float numere[10];"
            ],
            "answer": 1
          },
          {
            "question": "Care este indexul primului element dintr-un vector?",
            "options": [
              "0",
              "1",
              "10"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu vectori de întregi",
      "title": "Parcurgerea unui vector de întregi",
      "text": "Pentru a parcurge toate elementele unui vector de întregi, folosim o structură repetitivă precum `for`. Acest lucru ne permite să accesăm fiecare element pe rând, folosind indexul variabil.",
      "title2": "Exemplu de parcurgere a unui vector cu `for`",
      "text2": "Să presupunem că avem un vector de 5 elemente și dorim să afișăm toate valorile acestuia. Folosim bucla `for`:\n \nfor (int i = 0; i < 5; i=i+1) {\n    printf(\"Elementul %d este: %d\\n\", i, numere[i]);\n}\n \nAcest cod parcurge fiecare element din vector și îl afișează.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia83.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5] = {1, 2, 3, 4, 5}; // inițializare vector\n\n    // Parcurgem vectorul si afisam elementele\n    for (int i = 0; i < 5; i=i+1) {\n        printf(\"Elementul %d este: %d\\n\", i,numere[i]);\n    }\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum se parcurge un vector de întregi?",
            "options": [
              "Folosind o buclă `for`.",
              "Folosind o funcție `printf`.",
              "Folosind o declarație `int`."
            ],
            "answer": 0
          },
          {
            "question": "Ce face următorul cod?\n \nsuma += numere[i];\n ",
            "options": [
              "Atribuie valoarea `numere[i]` variabilei `suma`.",
              "Adună valoarea `numere[i]` la `suma`.",
              "Compară două numere."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu vectori de întregi",
      "title": "Suma elementelor unui vector",
      "text": "Un alt exemplu de utilizare a vectorilor este calcularea sumei tuturor elementelor dintr-un vector de întregi. Folosim o buclă `for` pentru a aduna fiecare element la suma totală:\n \nint suma = 0;\nfor (int i = 0; i < 5; i=i+1) {\n    suma += numere[i];\n}\nprintf(\"Suma elementelor este: %d\\n\", suma);\n ",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia84.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5] = {1, 2, 3, 4, 5}; // inițializare vector\n    int suma = 0;\n\n    // Calculam suma elementelor\n    for (int i = 0; i < 5; i=i+1) {\n        suma += numere[i];\n    }\n\n    printf(\"Suma elementelor este: %d\\n\", suma);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum se parcurge un vector de întregi?",
            "options": [
              "Folosind o buclă `for`.",
              "Folosind o funcție `printf`.",
              "Folosind o declarație `int`."
            ],
            "answer": 0
          },
          {
            "question": "Ce face următorul cod?\n \nsuma += numere[i];\n ",
            "options": [
              "Atribuie valoarea `numere[i]` variabilei `suma`.",
              "Adună valoarea `numere[i]` la `suma`.",
              "Compară două numere."
            ],
            "answer": 1
          }
        ]
      }
    }
  ];
  const lessonSetC9 = [
    {
      "mainTitle": "Vectori de șiruri de caractere în C",
      "title": "Ce este un vector de șiruri de caractere?",
      "text": "Un vector de șiruri de caractere este un tablou de șiruri, fiecare dintre acestea fiind de tip `char[]`. Practic, este o colecție de mai multe șiruri de caractere. În C, putem crea un vector de șiruri de caractere folosind un tablou bidimensional de caractere.",
      "title2": "Cum declarăm un vector de șiruri de caractere?",
      "text2": "Pentru a declara un vector de șiruri de caractere în C, folosim un tablou bidimensional de caractere. Fiecare șir de caractere este un șir de caractere obișnuit.\nExemplu:\n \nchar cuvinte[3][11] = {\"C\", \"Programare\", \"Vectori\"};\n \nAcest cod declară un vector cu 3 șiruri, fiecare șir putând avea până la 10 caractere, plus caracterul de terminare null (`\\0`).",
      "title3": "",
      "text3": "",
      "image": "/img/lectia91.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\nint main() {\n    // Declarare vector de siruri de caractere\n    char cuvinte[3][11] = {\"C\", \"Programare\", \"Vectori\"};\n\n    // Accesarea si afisarea elementelor din vector\n    printf(\"Primul cuvant: %s\\n\", cuvinte[0]);\n    printf(\"Al doilea cuvant: %s\\n\", cuvinte[1]);\n    printf(\"Al treilea cuvant: %s\\n\", cuvinte[2]);\n\n    // Modificarea unui element din vector\n    strcpy(cuvinte[0], \"Cod\");\n    printf(\"Primul cuvant modificat: %s\\n\", cuvinte[0]);\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum declarăm un vector de 3 șiruri de caractere, fiecare având maximum 10 caractere?",
            "options": [
              "char cuvinte[3][11];",
              "char cuvinte[10][3];",
              "char cuvinte[3];"
            ],
            "answer": 0
          },
          {
            "question": "Cum accesăm al doilea șir dintr-un vector de șiruri de caractere?",
            "options": [
              "printf(\"%s\", cuvinte[2]);",
              "printf(\"%s\", cuvinte[1]);",
              "printf(\"%s\", cuvinte[0]);"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Vectori de șiruri de caractere în C",
      "title": "Accesarea și modificarea elementelor unui șir de caractere",
      "text": "Accesarea unui element dintr-un vector de șiruri de caractere se face prin specificarea indexului șirului dorit. De exemplu:\n \nprintf(\"Primul cuvânt: %s\\n\", cuvinte[0]);\n \nAcest cod afișează primul șir de caractere din vector, adică `C`.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia92.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    // Declarare vector de siruri de caractere\n    char cuvinte[3][12] = {\"C\", \"Programare\", \"Vectori\"};\n\n    // Accesarea si afisarea elementelor din vector\n    printf(\"Primul cuvant: %s\\n\", cuvinte[0]);\n    printf(\"Al doilea cuvant: %s\\n\", cuvinte[1]);\n    printf(\"Al treilea cuvant: %s\\n\", cuvinte[2]);\n\n    // Modificarea unui element din vector\n    strcpy(cuvinte[0], \"Cod\");\n    printf(\"Primul cuvant modificat: %s\\n\", cuvinte[0]);\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum declarăm un vector de 3 șiruri de caractere, fiecare având maximum 10 caractere?",
            "options": [
              "char cuvinte[3][11];",
              "char cuvinte[10][3];",
              "char cuvinte[3];"
            ],
            "answer": 0
          },
          {
            "question": "Cum accesăm al doilea șir dintr-un vector de șiruri de caractere?",
            "options": [
              "printf(\"%s\", cuvinte[2]);",
              "printf(\"%s\", cuvinte[1]);",
              "printf(\"%s\", cuvinte[0]);"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu vectori de șiruri de caractere",
      "title": "Cum să parcurgem un vector de șiruri de caractere?",
      "text": "Pentru a parcurge toate șirurile de caractere dintr-un vector, putem folosi o buclă `for`. La fiecare iterație, putem accesa și afișa șirul curent utilizând indexul.",
      "title2": "Exemplu de parcurgere a unui vector de șiruri de caractere",
      "text2": "Iată un exemplu de cod pentru a parcurge un vector de șiruri și a le afișa pe toate:\n \n#include <stdio.h>\n\nint main() {\n    char cuvinte[3][12] = {\"C\", \"Programare\", \"Vectori\"};\n    for (int i = 0; i < 3; i=i+1) {\n        printf(\"Cuvantul %d: %s\\n\", i + 1, cuvinte[i]);\n    }\n    return 0;\n}\n \nÎn acest exemplu, bucla `for` parcurge vectorul de șiruri și afișează fiecare element al șirului împreună cu indexul său.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia93.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    char cuvinte[3][12] = {\"C\", \"Programare\", \"Vectori\"};\n    for (int i = 0; i < 3; i=i+1) {\n        printf(\"Cuvantul %d: %s\\n\", i + 1, cuvinte[i]);\n    }\n    return 0;\n}\n",
      "quiz": {
        "questions": [
          {
            "question": "Cum se poate să parcurgem un vector de șiruri de caractere?",
            "options": [
              "Folosind o buclă `void`.",
              "Folosind o buclă `for`.",
              "Folosind `printf` pentru fiecare element."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția `strcpy` în acest context?",
            "options": [
              "Copiază un șir într-un alt șir.",
              "Compară două șiruri.",
              "Afișează un șir pe ecran."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu vectori de șiruri de caractere",
      "title": "Modificarea elementelor dintr-un șir de caractere",
      "text": "Putem folosi o buclă `for` pentru a modifica fiecare șir din vector.",
      "title2": "Pentru a modifica toate elementele vectorului",
      "text2": "for (int i = 0; i < 3; i=i+1) { \n\tstrcpy(cuvinte[i], \"Modificat\");\n}",
      "title3": "Pentru a afișa elementele vectorului modificate",
      "text3": "for (int i = 0; i < 3; i=i+1){\n printf(\"Cuvantul %d: %s\\n\", i + 1, cuvinte[i]);\n} \n\n Explicația este că:\n\n\t\t în loc de %d (d-decimal) se pune valoarea expresiei i+1 \n\t\t iar în loc de %s (s - string) se pune valoarea referită de cuvinte[i].",
      "image": "/img/lectia94.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    char cuvinte[3][12] = {\"C\", \"Programare\", \"Vectori\"};\n\n    // Modificăm toate elementele vectorului\n    for (int i = 0; i < 3; i=i+1) {\n        strcpy(cuvinte[i], \"Modificat\");\n    }\n\n    // Afișăm elementele modificate\n    for (int i = 0; i < 3; i=i+1) {\n        printf(\"Cuvantul %d: %s\\n\", i + 1, cuvinte[i]);\n    }\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum parcurgem un vector de șiruri de caractere?",
            "options": [
              "Folosind o buclă `while`.",
              "Folosind o buclă `for`.",
              "Folosind `printf` pentru fiecare element."
            ],
            "answer": 1
          },
          {
            "question": "Ce face funcția `strcpy` în acest context?",
            "options": [
              "Copiază un șir într-un alt șir.",
              "Compară două șiruri.",
              "Afișează un șir pe ecran."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Sortarea unui vector de șiruri de caractere",
      "title": "Cum să sortăm un vector de șiruri de caractere?",
      "text": "Pentru a sorta un vector de șiruri de caractere în C, putem folosi funcția `strcmp` pentru a compara șirurile de caractere și să le reordonăm folosind un algoritm de sortare, cum ar fi sortarea prin selecție (`selection sort`).",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia95.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    char cuvinte[3][12] = {\"Vectori\", \"Programare\", \"C\"};\n    char temp[12];\n    int i, j;\n\n    // Sortare prin selecție\n    for (i = 0; i < 3 - 1; i=i+1) {\n        for (j = i + 1; j < 3; j=j+1) {\n            if (strcmp(cuvinte[i], cuvinte[j]) > 0) {\n                strcpy(temp, cuvinte[i]);\n                strcpy(cuvinte[i], cuvinte[j]);\n                strcpy(cuvinte[j], temp);\n            }\n        }\n    }\n\n    // Afișăm șirurile sortate\n    for (i = 0; i < 3; i=i+1) {\n        printf(\"%s\\n\", cuvinte[i]);\n    }\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce funcție folosim pentru a compara două șiruri de caractere?",
            "options": [
              "strcpy",
              "strlen",
              "strcmp"
            ],
            "answer": 2
          },
          {
            "question": "Cum sortăm un vector de șiruri de caractere?",
            "options": [
              "Comparăm și schimbăm elementele folosind `strcmp` și `strcpy`.",
              "Folosind `strlen` pentru fiecare șir.",
              "Afișăm elementele în ordine inversă."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Căutarea într-un vector de șiruri de caractere",
      "title": "Cum să căutăm un șir într-un vector de șiruri?",
      "text": "Căutarea unui șir într-un vector de șiruri se face prin parcurgerea elementelor și folosirea funcției `strcmp` pentru a compara fiecare element cu șirul căutat. Dacă se găsește un șir egal, returnăm poziția acestuia.",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia96.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\n// am definit propria functie _strcmp\nint _strcmp(const char str1[], const char str2[]) {\n    int i = 0;\n\n    // Compară caracterele unul câte unul\n    while (str1[i] != '\\0' && str2[i] != '\\0') {\n        if (str1[i] != str2[i]) {\n            return str1[i] - str2[i];\n        }\n        i++;\n    }\n\n    // Dacă am ajuns la finalul unuia dintre șiruri, comparăm lungimile\n    return str1[i] - str2[i];\n}\n\nint main() {\n    char cuvinte[3][12] = {\"C\", \"Programare\", \"Vectori\"};\n    char cautat[12] = \"Programare\";\n    int gasit = -1;\n\n    // Cautăm șirul\n    for (int i = 0; i < 3; i++) {\n        if (_strcmp(cuvinte[i], cautat) == 0) {\n            gasit = i;\n            break;\n        }\n    }\n\n    if (gasit != -1) {\n        printf(\"Sirul '%s' a fost gasit la pozitia %d.\\n\", cautat, gasit);\n    } else {\n        printf(\"Sirul '%s' nu a fost gasit.\\n\", cautat);\n    }\n    return 0;\n}\n",      "quiz": {
        "questions": [
          {
            "question": "Ce funcție folosim pentru a verifica dacă două șiruri sunt egale?",
            "options": [
              "strcpy",
              "strcmp",
              "strlen"
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă dacă șirul nu este găsit?",
            "options": [
              "Programul returnează eroare.",
              "Este afișat un mesaj că șirul nu a fost găsit.",
              "Ultimul element din vector este afișat."
            ],
            "answer": 1
          }
        ]
      }
    }
  ];

  const lessonSetC10 = [
    {
      "mainTitle": "Matrice în C",
      "title": "Ce este o matrice?",
      "text": "O matrice în limbajul C este o structură de date bidimensională care poate stoca valori de același tip, organizate în rânduri și coloane. Este similară cu un tabel de date. În cazul unei matrice de întregi, toate valorile stocate în matrice vor fi de tip `int`.",
      "title2": "Cum declarăm o matrice de întregi?",
      "text2": "Pentru a declara o matrice de întregi, folosim sintaxa:\n \nint matrice[3][4];\n \nAcest exemplu declară o matrice `matrice` cu 3 rânduri și 4 coloane, în care putem stoca 12 valori întregi. Fiecare element al matricei este accesibil folosind două indice: unul pentru rând și unul pentru coloană.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia101.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int matrice[2][3]; // declarare matrice de intregi cu 2 randuri si 3 coloane\n    matrice[0][0] = 1;\n    matrice[0][1] = 2;\n    matrice[0][2] = 3;\n    matrice[1][0] = 4;\n    matrice[1][1] = 5;\n    matrice[1][2] = 6;\n\n    printf(\"Primul element: %d\\n\", matrice[0][0]);\n    printf(\"Ultimul element: %d\\n\", matrice[1][2]);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum declarăm o matrice de întregi cu 2 rânduri și 5 coloane?",
            "options": [
              "int matrice[2][5];",
              "int matrice[5][2];",
              "int matrice[10];"
            ],
            "answer": 0
          },
          {
            "question": "Care sunt indicii primului element dintr-o matrice?",
            "options": [
              "[0][0]",
              "[1][0]",
              "[0][1]"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Matrice în C",
      "title": "Accesarea și modificarea elementelor unei matrice",
      "text": "Pentru a accesa sau modifica un element din matrice, folosim numele matricei urmat de indicii rândului și coloanei, între paranteze pătrate. Exemplu:\n \nmatrice[0][1] = 10; // atribuim valoarea 10 elementului de pe rândul 0, coloana 1\nprintf(\"Elementul de la [0][1] este: %d\\n\", matrice[0][1]); // afișăm elementul de pe rândul 0, coloana 1",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia102.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int matrice[2][2];\n    matrice[0][0] = 5;\n    matrice[0][1] = 10;\n    matrice[1][0] = 15;\n    matrice[1][1] = 20;\n\n    printf(\"Elementul de la [0][1]: %d\\n\", matrice[0][1]);\n    printf(\"Elementul de la [1][0]: %d\\n\", matrice[1][0]);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum accesăm elementul de pe rândul 1, coloana 2 dintr-o matrice?",
            "options": [
              "matrice[1][2];",
              "matrice[2][1];",
              "matrice[0][1];"
            ],
            "answer": 0
          },
          {
            "question": "Ce face codul următor?\n \nmatrice[0][1] = 7;",
            "options": [
              "Setează valoarea 7 la elementul din rândul 1, coloana 0.",
              "Setează valoarea 7 la elementul din rândul 0, coloana 1.",
              "Setează valoarea 7 la elementul din rândul 0, coloana 0."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu matrice în C",
      "title": "Parcurgerea unei matrice",
      "text": "Pentru a parcurge toate elementele unei matrice, folosim două bucle `for`, una pentru rânduri și una pentru coloane. Acest lucru ne permite să accesăm fiecare element pe rând și pe coloană.",
      "title2": "Exemplu de parcurgere a unei matrice",
      "text2": "Să presupunem că avem o matrice de 2x3 și dorim să afișăm toate valorile acesteia. Folosim două bucle `for`:\n \nfor (int i = 0; i < 2; i++) {\n    for (int j = 0; j < 3; j++) {\n        printf(\"Elementul [%d][%d] este: %d\\n\", i, j, matrice[i][j]);\n    }\n}",
      "title3": "",
      "text3": "",
      "image": "/img/lectia103.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int matrice[2][3] = {{1, 2, 3}, {4, 5, 6}};\n\n    // Parcurgem matricea și afișăm elementele\n    for (int i = 0; i < 2; i++) {\n        for (int j = 0; j < 3; j++) {\n            printf(\"Elementul [%d][%d] este: %d\\n\", i, j, matrice[i][j]);\n        }\n    }\n\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum se parcurge o matrice?",
            "options": [
              "Folosind o buclă `for` simplă.",
              "Folosind două bucle `for`, una în interiorul celeilalte.",
              "Folosind o buclă `while`."
            ],
            "answer": 1
          },
          {
            "question": "Ce face următorul cod?\n \nmatrice[i][j] = num;",
            "options": [
              "Atribuie valoarea `num` elementului de la poziția `[i][j]` în matrice.",
              "Afișează valoarea `num` la elementul `[i][j]`.",
              "Șterge valoarea `num` din matrice."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operații cu matrice în C",
      "title": "Suma elementelor unei matrice",
      "text": "Un alt exemplu de utilizare a matricelor este calcularea sumei tuturor elementelor dintr-o matrice de întregi. Folosim două bucle `for` pentru a aduna fiecare element la suma totală:\n \nint suma = 0;\nfor (int i = 0; i < 2; i++) {\n    for (int j = 0; j < 3; j++) {\n        suma += matrice[i][j];\n    }\n}\nprintf(\"Suma elementelor este: %d\\n\", suma);",
      "title2": "",
      "text2": "",
      "title3": "",
      "text3": "",
      "image": "/img/lectia104.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int matrice[2][3] = {{1, 2, 3}, {4, 5, 6}};\n    int suma = 0;\n\n    // Calculam suma elementelor\n    for (int i = 0; i < 2; i++) {\n        for (int j = 0; j < 3; j++) {\n            suma += matrice[i][j];\n        }\n    }\n\n    printf(\"Suma elementelor este: %d\\n\", suma);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum se parcurge o matrice pentru a calcula suma elementelor?",
            "options": [
              "Folosind o buclă `for` simplă.",
              "Folosind două bucle `for`, una în interiorul celeilalte.",
              "Folosind o buclă `while`."
            ],
            "answer": 1
          },
          {
            "question": "Ce face următorul cod?\n \nsuma += matrice[i][j];",
            "options": [
              "Adună valoarea `matrice[i][j]` la `suma`.",
              "Atribuie valoarea `matrice[i][j]` la `suma`.",
              "Scade valoarea `matrice[i][j]` din `suma`."
            ],
            "answer": 0
          }
        ]
      }
    }
  ];

  const lessonSetC11 = [
    {
      "mainTitle": "Variabile și tipuri de date – Problema",
      "title": "Enunț problemă",
      "text": "Să se declare două variabile de tip întreg (int) x și y. Să se declare o variabilă de tip întreg (int) produs. Să se seteze valorile variabilelor x și y la 6 și 7. Să se calculeze produsul valorilor x și y și să se stocheze în variabila produs. Afișați rezultatul pe ecran. Programul trebuie să se încheie cu return 0.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nDedesubt, scriem funcția main:\n\tint main() {\n\t\t// aici vom insera codul de mai jos\n\t\treturn 0;\n\t}\nDeclarăm variabilele x și y de tip int: scriem int x; int y;\nDeclarăm variabila produs de tip int: scriem int produs;\nSetăm valorile variabilelor: x = 6; y = 7;\nCalculăm produsul: produs = x * y;\nAfișăm produsul pe ecran: printf(\"Produsul este %d\\n\", produs);\n",
      "title3": "",
      "text3": "",
      "image": "/img/lectia24.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n  int x, y, produs;\n  x = 6;\n  y = 7;\n  produs = x * y;\n  printf(\"Produsul este %d\\n\", produs);\n  return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date specifică int?",
            "options": [
              "tipul real",
              "tipul șir de caractere",
              "tipul întreg"
            ],
            "answer": 2
          },
          {
            "question": "Cum se calculează produsul a două variabile întregi?",
            "options": [
              "produs = x + y;",
              "produs = x - y;",
              "produs = x * y;"
            ],
            "answer": 2
          }
        ]
      }
    },
    {
      "mainTitle": "Afișarea datelor pe ecran – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare două variabile de tip întreg (int) x și y. Să se calculeze produsul acestora într-o variabilă produs și să se afișeze pe ecran utilizând printf. În plus, să se declare o variabilă de tip caracter (char) și să se afișeze pe ecran.",
      "title2": "Rezolvare",
      "text2": "Se includ librăria stdio.h: #include <stdio.h>\nSe declară variabilele x și y de tip întreg și o variabilă produs de tip întreg. Se declară o variabilă caracter (char). Se setează valorile variabilelor x și y, se calculează produsul și se afișează împreună cu variabila caracter.",
      "title3": "Cod complet",
      "text3": nume + ", te rog sa apesi butonul 'Afiseaza cod' pentru a afisa rezolvarea acestei probleme!",
      "image": "/img/lectia34.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int x = 5;\n    int y = 3;\n    int produs = x * y;\n    char c = 'A';\n    printf(\"Produsul este %d\\n\", produs);\n    printf(\"Caracterul este %c\\n\", c);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este specificatorul pentru afișarea unui caracter?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 2
          },
          {
            "question": "Care este specificatorul pentru afișarea unui întreg (int)?",
            "options": [
              "%d",
              "%f",
              "%c",
              "%s"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori aritmetici – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare trei variabile de tip întreg (int) a, b și rezultat. Să se seteze valorile variabilelor a și b cu 12 și 5. Să se calculeze suma, diferența, produsul și restul împărțirii între a și b, și să se afișeze fiecare rezultat pe ecran.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nScriem funcția main și folosim operatorii aritmetici pentru a calcula suma, diferența, produsul și restul împărțirii între cele două variabile a și b. Fiecare rezultat este afișat pe ecran cu printf.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabilele a și b de tip întreg, le setăm valorile, iar apoi calculăm și afișăm rezultatele diferitelor operații:",
      "image": "/img/operatori_aritmetici.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int a = 12;\n    int b = 5;\n    int rezultat;\n    rezultat = a + b;\n    printf(\"Suma: %d\\n\", rezultat);\n    rezultat = a - b;\n    printf(\"Diferenta: %d\\n\", rezultat);\n    rezultat = a * b;\n    printf(\"Produsul: %d\\n\", rezultat);\n    rezultat = a % b;\n    printf(\"Restul impartirii: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce operator aritmetic se folosește pentru a calcula restul împărțirii?",
            "options": [
              "+",
              "-",
              "/",
              "%"
            ],
            "answer": 3
          },
          {
            "question": "Care este rezultatul calculului 12 % 5?",
            "options": [
              "2",
              "3",
              "5",
              "7"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori de comparație – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare o variabilă de tip întreg (int) n. Să se seteze valoarea variabilei cu 20. Să se verifice dacă valoarea variabilei este mai mare sau egală cu 18 și să se afișeze pe ecran un mesaj corespunzător.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nScriem funcția main și folosim operatorul >= pentru a verifica dacă variabila n este mai mare sau egală cu 18. Afișăm un mesaj corespunzător pe ecran.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabila n de tip int, îi setăm valoarea și verificăm cu ajutorul operatorului >= daca variabila n are o valoare mai mare decat 18.",
      "image": "/img/operatori_comparatie.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int n = 20;\n    if (n >= 18) {\n        printf(\"n este mai mare sau egal cu 18\\n\");\n    } else {\n        printf(\"n este mai mic decat 18\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce operator se folosește pentru a verifica dacă un număr este mai mare sau egal cu altul?",
            "options": [
              ">=",
              "<=",
              "==",
              "!="
            ],
            "answer": 0
          },
          {
            "question": "Ce rezultat obțineți dacă n este 20 și condiția este n >= 18?",
            "options": [
              "Afișează 'n este mai mic decât 18'",
              "Afișează 'n este mai mare sau egal cu 18'",
              "Nu afișează nimic",
              "Afișează un mesaj de eroare"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori aritmetici – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare două variabile de tip întreg (int) x și y. Să se seteze valorile variabilelor cu 17 și 5. Să se calculeze restul împărțirii valorilor x și y și să se afișeze pe ecran rezultatul.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nScriem funcția main și folosim operatorul % pentru a calcula restul împărțirii variabilelor x și y. Afișăm rezultatul pe ecran folosind printf.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabilele x și y de tip int, le setăm valorile și apoi calculăm și afișăm restul împărțirii:",
      "image": "/img/operatori_rest.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int x = 17;\n    int y = 5;\n    int rezultat = x % y;\n    printf(\"Restul impartirii lui %d si %d este: %d\\n\", x, y, rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este operatorul pentru restul împărțirii?",
            "options": [
              "+",
              "-",
              "*",
              "%"
            ],
            "answer": 3
          },
          {
            "question": "Care este rezultatul împărțirii 17 % 5?",
            "options": [
              "0",
              "2",
              "3",
              "4"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Operatori de comparație – problemă",
      "title": "Enunț problemă",
      "text": "Să se declare două variabile de tip întreg (int) a și b. Să se seteze valorile variabilelor cu 10 și 15. Să se verifice dacă valorile variabilelor sunt egale sau diferite și să se afișeze pe ecran un mesaj corespunzător.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h: #include <stdio.h>\nScriem funcția main și folosim operatorii == și != pentru a verifica egalitatea și diferența între variabilele a și b. Afișăm un mesaj corespunzător pentru fiecare caz.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabilele a și b de tip int, le setăm valorile și verificăm dacă sunt egale sau diferite.",
      "image": "/img/operatori_comparatie_egal_diferit.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int a = 10;\n    int b = 15;\n    if (a == b) {\n        printf(\"a si b sunt egale\\n\");\n    } else if (a != b) {\n        printf(\"a si b sunt diferite\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care operator verifică dacă două numere sunt egale?",
            "options": [
              ">",
              "<",
              "==",
              "!="
            ],
            "answer": 2
          },
          {
            "question": "Care operator verifică dacă două numere sunt diferite?",
            "options": [
              ">",
              "<",
              "==",
              "!="
            ],
            "answer": 3
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea if – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care initializează o variabila de tip numar intreg și verifică dacă numărul este par sau impar. Dacă numărul este par, să se afișeze mesajul \"Numărul este par\", iar dacă este impar să se afișeze mesajul \"Numărul este impar\".",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h și scriem funcția main. Folosim operatorul % pentru a verifica restul împărțirii numărului la 2. Dacă restul este 0, numărul este par, altfel este impar.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabila numar de tip int și folosim operatorul % pentru a determina dacă numărul este par sau impar.",
      "image": "/img/if_else_problem1.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int numar;\n    numar=10;\n    if (numar % 2 == 0) {\n        printf(\"Numarul este par\\n\");\n    } else {\n        printf(\"Numarul este impar\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face operatorul % în acest caz?",
            "options": [
              "Verifică dacă un număr este par.",
              "Împărțirea a două numere.",
              "Verifică dacă un număr este impar."
            ],
            "answer": 0
          },
          {
            "question": "Ce mesaj se afișează dacă numărul este impar?",
            "options": [
              "\"Numărul este impar\"",
              "\"Numărul este par\"",
              "\"Nu se afișează nimic.\""
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea if-else – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care initializează o variabila de tip numar intreg și verifică dacă numărul este pozitiv, negativ sau zero. Dacă numărul este pozitiv, să se afișeze mesajul \"Numărul este pozitiv\", dacă este negativ, să se afișeze mesajul \"Numărul este negativ\", iar dacă este zero, să se afișeze mesajul \"Numărul este zero\".",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h și scriem funcția main. Folosim instrucțiunea if-else pentru a verifica dacă numărul este pozitiv, negativ sau zero.",
      "title3": "Idee de baza",
      "text3": "Declarăm variabila numar de tip int și folosim instrucțiunea if-else pentru a determina natura numărului.",
      "image": "/img/if_else_problem2.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int numar;\n    numar=-10;\n    if (numar > 0) {\n        printf(\"Numarul este pozitiv\\n\");\n    } else if (numar < 0) {\n        printf(\"Numarul este negativ\\n\");\n    } else {\n        printf(\"Numarul este zero\\n\");\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce mesaj se afișează dacă numărul este pozitiv?",
            "options": [
              "\"Numărul este pozitiv\"",
              "\"Numărul este negativ\"",
              "\"Numărul este zero\""
            ],
            "answer": 0
          },
          {
            "question": "Ce condiție verifică dacă un număr este negativ?",
            "options": [
              "numar > 0",
              "numar < 0",
              "numar == 0"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea while – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care afișează pe ecran toate numerele pare între 1 și 10, folosind instrucțiunea while.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h și scriem funcția main. Folosim o variabilă i inițializată cu 2 și repetăm bucla while cât timp i este mai mic sau egal cu 10. La fiecare iterație, afișăm valoarea lui i și apoi incrementăm i cu 2.",
      "title3": "Idee de baza",
      "text3": "Folosim o buclă while pentru a afișa numerele pare între 1 și 10.",
      "image": "/img/lectia_while_problem.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int i = 2;\n    while (i <= 10) {\n        printf(\"%d\\n\", i);\n        i += 2;\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce face bucla while?",
            "options": [
              "Execută codul o singură dată.",
              "Execută codul repetitiv cât timp condiția este adevărată.",
              "Execută codul dacă condiția este falsă."
            ],
            "answer": 1
          },
          {
            "question": "Ce se întâmplă când i devine mai mare decât 10?",
            "options": [
              "Bucla continuă să ruleze.",
              "Bucla se oprește.",
              "Programul afișează un mesaj de eroare."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Instrucțiunea for – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care calculează și afișează suma numerelor întregi de la 1 la 5, folosind instrucțiunea for.",
      "title2": "Rezolvare",
      "text2": "Includem librăria stdio.h și scriem funcția main. Folosim o variabilă sum pentru a aduna numerele și o buclă for pentru a repeta adunarea numerelor de la 1 la 5.",
      "title3": "Idee de baza",
      "text3": "Folosim o buclă for pentru a calcula suma numerelor de la 1 la 5 și apoi o afișăm.",
      "image": "/img/lectia_for_problem.png",
      "codeSnippet": "#include <stdio.h>\nint main() {\n    int sum = 0;\n    for (int i = 1; i <= 5; i=i+1) {\n        sum += i;\n    }\n    printf(\"Suma numerelor este: %d\\n\", sum);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este rezultatul final al sumei?",
            "options": [
              "Suma numerelor de la 1 la 5 este 15.",
              "Suma numerelor de la 1 la 5 este 10.",
              "Suma numerelor de la 1 la 5 este 5."
            ],
            "answer": 0
          },
          {
            "question": "De câte ori se execută bucla for?",
            "options": [
              "De 5 ori.",
              "De 6 ori.",
              "De 10 ori."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Funcțiile în C – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie o funcție care calculează pătratul unui număr întreg și să se folosească această funcție în funcția `main` pentru a calcula și afișa pătratul numărului 7.",
      "title2": "Rezolvare",
      "text2": "Se definește funcția `patrat` care primește un argument de tip `int` și returnează rezultatul înmulțirii acestuia cu el însuși. Funcția `main` va apela această funcție pentru a calcula pătratul numărului 7 și va afișa rezultatul.",
      "title3": "Idee de baza",
      "text3": "Funcția `patrat` calculează pătratul unui număr și returnează rezultatul către funcția `main`, care afișează rezultatul.",
      "image": "/img/function_square_problem.png",
      "codeSnippet": "#include <stdio.h>\nint patrat(int n) {\n    return n * n;\n}\nint main() {\n    int rezultat = patrat(7);\n    printf(\"Patratul lui 7 este: %d\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date returnează funcția `patrat`?",
            "options": [
              "void",
              "int",
              "float"
            ],
            "answer": 1
          },
          {
            "question": "Ce valoare returnează funcția `patrat(7)`?",
            "options": [
              "7",
              "14",
              "49"
            ],
            "answer": 2
          }
        ]
      }
    },
    {
      "mainTitle": "Funcțiile de tip void – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie o funcție de tip `void` care afișează mesajul 'Bun venit!' pe ecran. Funcția va fi apelată din funcția `main`.",
      "title2": "Rezolvare",
      "text2": "Se definește funcția `afisareMesaj` de tip `void`, care nu primește argumente și nici nu returnează vreo valoare. Această funcție va afișa mesajul 'Bun venit!' pe ecran. Funcția va fi apelată din funcția `main`.",
      "title3": "Idee de baza",
      "text3": "Funcția `afisareMesaj` afișează un mesaj pe ecran și este apelată în funcția `main`.",
      "image": "/img/function_void_problem.png",
      "codeSnippet": "#include <stdio.h>\nvoid afisareMesaj() {\n    printf(\"Bun venit!\\n\");\n}\nint main() {\n    afisareMesaj();\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce tip de date returnează funcția `afisareMesaj`?",
            "options": [
              "void",
              "int",
              "char"
            ],
            "answer": 0
          },
          {
            "question": "Ce face funcția `afisareMesaj()`?",
            "options": [
              "Returnează o valoare.",
              "Afișează un mesaj pe ecran.",
              "Nu face nimic."
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Vectori de întregi – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care declară un vector de 5 numere întregi. Programul trebuie să calculeze și să afișeze valoarea maximă și valoarea minimă din acest vector, fără a folosi citirea de la tastatură.",
      "title2": "Rezolvare",
      "text2": "Declarați un vector de 5 elemente și inițializați-l cu valori fixe. Folosiți o buclă `for` pentru a parcurge vectorul și a determina valorile maxime și minime.",
      "title3": "",
      "text3": "",
      "image": "/img/vector_problem1.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5];\n    numere[0]=3;numere[1]=7;numere[2]=2;numere[3]=9;numere[4]=5;\n    int max = numere[0];\n    int min = numere[0];\n\n    // Găsim valoarea maximă și minimă\n    for (int i = 1; i < 5; i=i+1) {\n        if (numere[i] > max) {\n            max = numere[i];\n        }\n        if (numere[i] < min) {\n            min = numere[i];\n    }    \n}\n    printf(\"Valoarea maxima este: %d\\n\", max);\n    printf(\"Valoarea minima este: %d\\n\", min);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum determinăm valoarea maximă într-un vector?",
            "options": [
              "Comparăm fiecare element și actualizăm valoarea maximă.",
              "Afișăm primul element.",
              "Comparăm doar ultimele două elemente."
            ],
            "answer": 0
          },
          {
            "question": "Ce face acest cod?\n \nif (numere[i] < min) {\n    min = numere[i];\n}",
            "options": [
              "Atribuie valoarea `numere[i]` variabilei `min` dacă este mai mică.",
              "Compară două valori și afișează rezultatul.",
              "Ignoră valoarea curentă din vector."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Vectori de întregi – problemă",
      "title": "Enunț problemă",
      "text": "Să se scrie un program care declară un vector de 6 numere întregi și le inversează ordinea. Programul trebuie să afișeze vectorul inițial și vectorul după inversare.",
      "title2": "Rezolvare",
      "text2": "Declarați un vector de 6 elemente și inițializați-l cu valori fixe. Folosiți două variabile pentru a parcurge vectorul de la început și de la sfârșit, schimbând valorile între ele.",
      "title3": "Idee de baza",
      "text3": "Vectorul este inițializat, apoi folosim o buclă pentru a inversa elementele vectorului și le afișăm înainte și după inversare.",
      "image": "/img/vector_problem2.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[6] = {1, 2, 3, 4, 5, 6};\n\n    // Afișăm vectorul inițial\n    printf(\"Vectorul initial: \");\n    for (int i = 0; i < 6; i=i+1) {\n        printf(\"%d \", numere[i]);\n    }\n    printf(\"\\n\");\n\n    // Inversăm vectorul\n    for (int i = 0; i < 3; i=i+1) {\n        int temp = numere[i];\n        numere[i] = numere[5 - i];\n        numere[5 - i] = temp;\n    }\n\n    // Afișăm vectorul după inversare\n    printf(\"Vectorul inversat: \");\n    for (int i = 0; i < 6; i=i+1) {\n        printf(\"%d \", numere[i]);\n    }\n    printf(\"\\n\");\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum inversăm elementele unui vector?",
            "options": [
              "Schimbăm valorile dintre începutul și sfârșitul vectorului.",
              "Sortăm vectorul în ordine descrescătoare.",
              "Adunăm toate valorile vectorului."
            ],
            "answer": 0
          },
          {
            "question": "Care este rezultatul inversării vectorului {1, 2, 3, 4, 5, 6}?",
            "options": [
              "{6, 5, 4, 3, 2, 1}",
              "{1, 2, 3, 4, 5, 6}",
              "{2, 3, 4, 5, 6, 1}"
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Afișarea lungimii șirurilor dintr-un vector de șiruri de caractere",
      "title": "Enunț problemă",
      "text": "Să se declare un vector de șiruri de caractere cu 3 elemente: \"Informatica\", \"Limbaj\", \"C\". Să se parcurgă fiecare element al vectorului și să se afișeze lungimea fiecărui șir utilizând funcția `strlen` din biblioteca standard `string.h`.",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm vectorul de șiruri de caractere.\n2. Utilizăm o buclă `for` pentru a parcurge vectorul.\n3. Pentru fiecare element, folosim funcția `strlen` pentru a calcula lungimea șirului și afișăm rezultatul folosind `printf`.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia101.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    char cuvinte[3][12] = {\"Informatica\", \"Limbaj\", \"C\"};\n    for (int i = 0; i < 3; i=i+1) {\n        printf(\"Lungimea cuvantului '%s' este: %d\\n\", cuvinte[i], strlen(cuvinte[i]));\n    }\n    return 0;\n}",      "quiz": {
        "questions": [
          {
            "question": "Ce funcție folosim pentru a calcula lungimea unui șir de caractere?",
            "options": [
              "strcmp",
              "strcpy",
              "strlen"
            ],
            "answer": 2
          },
          {
            "question": "Care este lungimea șirului \"Informatica\"?",
            "options": [
              "10",
              "11",
              "9"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Concatenarea șirurilor dintr-un vector de șiruri de caractere",
      "title": "Enunț problemă",
      "text": "Să se declare un vector de șiruri de caractere cu 3 elemente: \"Programare\", \"în\", \"C\". Să se concateneze toate aceste șiruri într-un singur șir și să se afișeze rezultatul.",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm un vector de șiruri de caractere și un șir de rezultat.\n2. Utilizăm o buclă `for` pentru a parcurge vectorul.\n3. Pentru fiecare element, folosim funcția `strcat` pentru a adăuga șirurile în șirul rezultat.\n4. Afișăm șirul rezultat.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia102.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    char cuvinte[3][12] = {\"Programare\", \"in\", \"C\"};\n    char rezultat[50] = \"\";\n    for (int i = 0; i < 3; i=i+1) {\n        strcat(rezultat, cuvinte[i]);\n        strcat(rezultat, \" \"); // adăugăm un spațiu între cuvinte\n    }\n    printf(\"Sirul concatenat este: %s\\n\", rezultat);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce funcție folosim pentru a concatena două șiruri de caractere?",
            "options": [
              "strcpy",
              "strcat",
              "strcmp"
            ],
            "answer": 1
          },
          {
            "question": "Care este rezultatul concatenării șirurilor \"Programare\", \"în\", \"C\"?",
            "options": [
              "\"ProgramareînC\"",
              "\"Programare în C \"",
              "\"Programare în C\""
            ],
            "answer": 2
          }
        ]
      }
    },
    {
      "mainTitle": "Verificarea numerelor pare și impare",
      "title": "Enunț problemă",
      "text": "Să se declare un vector de întregi cu 5 elemente: `2, 7, 4, 9, 6`. Să se parcurgă vectorul și să se afișeze pentru fiecare element dacă este par sau impar.",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm vectorul de întregi cu 5 elemente.\n2. Utilizăm o buclă `for` pentru a parcurge fiecare element al vectorului.\n3. Verificăm fiecare element dacă este par (folosind operatorul `%`).\n4. Afișăm dacă este par sau impar.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia111.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numere[5] = {2, 7, 4, 9, 6};\n    for (int i = 0; i < 5; i=i+1) {\n        if (numere[i] % 2 == 0) {\n            printf(\"%d este par\\n\", numere[i]);\n        } else {\n            printf(\"%d este impar\\n\", numere[i]);\n        }\n    }\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Cum verificăm dacă un număr este par?",
            "options": [
              "Verificăm dacă numărul este mai mare decât 0.",
              "Verificăm dacă numărul este divizibil cu 2.",
              "Folosim funcția `sqrt`."
            ],
            "answer": 1
          },
          {
            "question": "Care este rezultatul pentru numărul `7`?",
            "options": [
              "Par",
              "Impar",
              "Niciuna"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Calcularea factorialului unui număr",
      "title": "Enunț problemă",
      "text": "Să se calculeze factorialul unui număr întreg `5`. Factorialul unui număr `n` se definește ca `n! = n * (n-1) * (n-2) * ... * 1`.",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm o variabilă întregă pentru număr și una pentru a stoca rezultatul.\n2. Inițializăm variabila rezultat la `1`.\n3. Utilizăm o buclă `for` pentru a calcula factorialul, multiplicând succesiv fiecare număr până la `n`.\n4. Afișăm rezultatul.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia112.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int n = 5;\n    int factorial = 1;\n    for (int i = 1; i <= n; i=i+1) {\n        factorial *= i;\n    }\n    printf(\"Factorialul lui %d este: %d\\n\", n, factorial);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce valoare va afișa programul pentru `n = 5`?",
            "options": [
              "5",
              "120",
              "25"
            ],
            "answer": 1
          },
          {
            "question": "Care este valoarea factorialului lui `0`?",
            "options": [
              "0",
              "1",
              "Nedefinit"
            ],
            "answer": 1
          }
        ]
      }
    },
    {
      "mainTitle": "Inversarea unui șir de caractere",
      "title": "Enunț problemă",
      "text": "Să se declare un șir de caractere `\"Hello\"` și să se afișeze inversul acestuia: `\"olleH\"`.",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm un șir de caractere și aflăm lungimea acestuia.\n2. Utilizăm două indicatoare: unul la începutul șirului și unul la sfârșit.\n3. Schimbăm caracterele între ele și mutăm indicatoarele până când acestea se întâlnesc la mijloc.\n4. Afișăm șirul inversat.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia113.png",
      "codeSnippet": "#include <stdio.h>\n#include <string.h>\n\nint main() {\n    char sir[] = \"Hello\";\n    int n = strlen(sir);\n    for (int i = 0; i < n / 2; i=i+1) {\n        char temp = sir[i];\n        sir[i] = sir[n - i - 1];\n        sir[n - i - 1] = temp;\n    }\n    printf(\"Sirul inversat este: %s\\n\", sir);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Care este șirul inversat al `\"World\"`?",
            "options": [
              "\"dlroW\"",
              "\"World\"",
              "\"Wrold\""
            ],
            "answer": 0
          },
          {
            "question": "Ce face funcția `strlen`?",
            "options": [
              "Calculează lungimea unui șir de caractere.",
              "Inversă un șir de caractere.",
              "Compară două șiruri."
            ],
            "answer": 0
          }
        ]
      }
    },
    {
      "mainTitle": "Calcularea sumei cifrelor unui număr",
      "title": "Enunț problemă",
      "text": "Să se declare un număr întreg `12345` și să se calculeze suma cifrelor sale (`1 + 2 + 3 + 4 + 5 = 15`).",
      "title2": "Rezolvare",
      "text2": "Pentru a rezolva această problemă, urmărim pașii:\n1. Declarăm o variabilă întregă pentru număr și una pentru a stoca suma.\n2. Utilizăm o buclă `while` pentru a extrage fiecare cifră folosind operatorul `%` și `/`.\n3. Adunăm cifrele la suma totală.\n4. Afișăm suma rezultată.",
      "title3": "",
      "text3": "",
      "image": "/img/lectia114.png",
      "codeSnippet": "#include <stdio.h>\n\nint main() {\n    int numar = 12345;\n    int suma = 0;\n    while (numar > 0) {\n        suma += numar % 10;\n        numar /= 10;\n    }\n    printf(\"Suma cifrelor este: %d\\n\", suma);\n    return 0;\n}",
      "quiz": {
        "questions": [
          {
            "question": "Ce valoare va afișa programul pentru `123`?",
            "options": [
              "6",
              "123",
              "3"
            ],
            "answer": 0
          },
          {
            "question": "Ce operator folosim pentru a extrage ultima cifră a unui număr?",
            "options": [
              "+",
              "%",
              "/"
            ],
            "answer": 1
          }
        ]
      }
    }
    
  ];

  

  // Funcție pentru a selecta setul de lecții pe baza lessonSetId din URL
  const getLessonSet = (setId) => {
    switch (setId) {
      case '1':
        return lessonSetC1;
      case '2':
        return lessonSetC2;
      case '3':
        return lessonSetC3;
      case '4':
        return lessonSetC4;
      case '5':
        return lessonSetC5;
      case '16':
        return lessonSetC6;
      case '17':
        return lessonSetC7;
      case '18':
        return lessonSetC8;
      case '19':
        return lessonSetC9;
      case '20':
        return lessonSetC10;
      case '21':
          return lessonSetC11;
      default:
        return lessonSetC1; // Set default dacă nu găsește setul
    }
  };

  // Preluăm setul de lecții pe baza parametrului din URL
  const selectedLessonSet = getLessonSet(lessonSetId);

  // Efect pentru a seta lecția curentă bazată pe `lessonId` din URL
  useEffect(() => {
    const lessonIndex = parseInt(lessonId, 10) - 1; // Convertim id-ul lecției din URL (ex: 1 -> index 0)

    if (lessonIndex >= 0 && lessonIndex < selectedLessonSet.length) {
      setCurrentLesson(lessonIndex);
    } else {
      setCurrentLesson(0); // Lecție implicită la 0 dacă id-ul lecției nu e valid
    }
  }, [lessonId, selectedLessonSet.length]);

  const toggleHighlights = () => {
    setShowHighlights(!showHighlights);
  };

  const handleQuizAnswer = (questionIndex, optionIndex) => {
    setQuizAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: optionIndex,
    }));
  };

  const checkQuizAnswers = () => {
    const currentQuiz = selectedLessonSet[currentLesson].quiz;
    let score = 0;
    currentQuiz.questions.forEach((q, index) => {
      if (quizAnswers[index] === q.answer) {
        score++;
      }
    });
    alert(`Scorul dumneavoastră este ${score} din maxim ${currentQuiz.questions.length} puncte!`);
  };

  const goToNextLesson = () => {
    const nextLessonIndex = (currentLesson + 1) % selectedLessonSet.length;
    const nextLessonId = nextLessonIndex + 1;
    navigate(`/lectii/${lessonSetId}/${nextLessonId}`);
    setShowQuiz(false);
    setQuizAnswers({});
  };

  const goToPreviousLesson = () => {
    const prevLessonIndex = (currentLesson - 1 + selectedLessonSet.length) % selectedLessonSet.length;
    const prevLessonId = prevLessonIndex + 1;
    navigate(`/lectii/${lessonSetId}/${prevLessonId}`);
    setShowQuiz(false);
    setQuizAnswers({});
  };

  return (
    <Container fluid className="p-2" style={styles.app}>
      {/* Row for the Highlights Toggle Button */}
      <Row>
        <Col md={{ span: 3, offset: 9 }}>
          <Card>
            <Row>
              <Col>
                <Button
                  onClick={toggleHighlights}
                  variant={showHighlights ? "warning" : "primary"}
                  title="Click pe acest buton pentru ajutor"
                >
                  {showHighlights ? "Ascunde Ajutor" : "Arată Ajutor"}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
  
      {/* Row for EditorPanel and Quiz/Canvas Panel */}
      <Row>
        {/* Left Column: EditorPanel */}
        <Col lg={4} md={12} xs={12}>
          <Card>
            <EditorPanel
              output={output}
              setOutput={setOutput}
              currentLesson={currentLesson}
              lessonContent={selectedLessonSet}
              showHighlights={showHighlights}
            />
          </Card>
        </Col>
  
        {/* Right Column: QuizPanel or CanvasPanel and Navigation */}
        <Col lg={7} md={12} xs={12} id="panel2" style={styles.panel2}>
          <Card className="mb-3">
            <h3 style={styles.help} className={showHighlights ? "text-success" : "d-none"}>
              Buna {nume}, aici poți vizualiza lecții sau întrebări!
            </h3>
            {showQuiz ? (
              <QuizPanel
                lessonContent={selectedLessonSet}
                currentLesson={currentLesson}
                quizAnswers={quizAnswers}
                handleQuizAnswer={handleQuizAnswer}
                checkQuizAnswers={checkQuizAnswers}
                goToNextLesson={goToNextLesson}
              />
            ) : (
              <CanvasPanel
                lessonContent={selectedLessonSet}
                currentLesson={currentLesson}
                showQuiz={showQuiz}
              />
            )}
          </Card>
  
          <h3 className={showHighlights ? "text-success" : "d-none"}>
            Bună {nume}, aici poți naviga între lecții și îți poți testa cunoștințele!
          </h3>
  
          {/* Lesson Navigation */}
          <LessonNavigation
            goToNextLesson={goToNextLesson}
            goToPreviousLesson={goToPreviousLesson}
            showQuiz={showQuiz}
            setShowQuiz={setShowQuiz}
          />
        </Col>
      </Row>
    </Container>
  );
  
};

const styles = {
  highlight: {
    backgroundColor: 'yellow',
  },
  nodisplay: {
    display: 'none',
    visibility: 'none',
  },
  panel2: {
    float: 'left',
    marginRight: '5%',
  },
  app: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1900px',
  },
  button: {
    backgroundColor: '#007BFF',
    padding: '10px 25px',
    margin: '5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#FFF',
  },
  help: {
    paddingLeft: '38px'
  }
};

export default EditorApp;

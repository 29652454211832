import React, { useState, useEffect } from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './LessonList.css'

function generateAccessCode () {
  const currentDate = new Date()
  const monthNames = [
    'IANUARIE',
    'FEBRUARIE',
    'MARTIE',
    'APRILIE',
    'MAI',
    'IUNIE',
    'IULIE',
    'AUGUST',
    'SEPTEMBRIE',
    'OCTOMBRIE',
    'NOIEMBRIE',
    'DECEMBRIE'
  ]
  const currentMonthName = monthNames[currentDate.getMonth()]
  const reversedMonth = currentMonthName
    .slice(0, 3)
    .split('')
    .reverse()
    .join('')
  const monthMod = (currentDate.getMonth() + 2) % 3
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay()
  const firstDayDigit = firstDayOfMonth === 0 ? 1 : firstDayOfMonth + 1
  const accessCode = `${reversedMonth}${monthMod}${firstDayDigit}`
  return accessCode
}

// Data for lessons
const lessons = [
  { id: 1, title: 'Cum să folosesc aplicația?', type: 'gratuit' },
  { id: 2, title: 'Variabile și tipuri de date', type: 'gratuit' },
  { id: 3, title: 'Afișarea datelor pe ecran', type: 'gratuit' },
  { id: 4, title: 'Operatori', type: 'gratuit' },
  { id: 5, title: 'Instrucțiunea if', type: 'gratuit' },
  { id: 16, title: 'Instrucțiuni repetitive', type: 'plătit' },
  { id: 17, title: 'Funcții', type: 'plătit' },
  { id: 18, title: 'Șiruri de întregi (vectori)', type: 'plătit' },
  { id: 19, title: 'Șiruri de caractere', type: 'plătit' },
  { id: 20, title: 'Matrice in C', type: 'plătit' },
  { id: 21, title: 'Probleme de rezolvat', type: 'plătit' }
]
const PayPalPayment = ({ onPaymentSuccess }) => {
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'AUl5XdZ3ppLbBlG4csGnq_JWML-1LZy0qOhSUIF5XJsYWED2_5xhPINWZIJN5Qjy1rDZoPhLlqTcD9Vr'
      }}
    >
      <PayPalButtons
        style={{ layout: 'vertical' }}
        createOrder={(data, actions) => {
          // Setting the currency to Euro (EUR)
          return actions.order.create({
            purchase_units: [
              { amount: { currency_code: 'USD', value: '10' } } // Price in Euro
            ]
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(details => {
            const name = details.payer.name.given_name;
            alert(`Mulțumim pentru plată, ${name}!`);
            const accessCode = generateAccessCode();
            alert(
              `Codul de acces la lecții pentru luna curentă este: ${accessCode}. Te rog să îl notezi bine!`
            );
            onPaymentSuccess(accessCode);
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

const LessonRow = ({ lesson, onAccess }) => (
  <Button
    className='mybutton'
    variant='primary'
    onClick={() => onAccess(lesson.id)}
  >
    {lesson.title}
  </Button>
)

const LessonList = () => {
  const navigate = useNavigate()
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [accessCode, setAccessCode] = useState(null)
  const [userCode, setUserCode] = useState('')
  const [manualAccess, setManualAccess] = useState(false)

  useEffect(() => {
    const storedAccess = localStorage.getItem('hasAccess')
    if (storedAccess === 'true') {
      setManualAccess(true)
      setPaymentSuccess(false)
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const codeFromUrl = urlParams.get('cod')
    if (codeFromUrl && codeFromUrl === generateAccessCode()) {
      setManualAccess(true)
      localStorage.setItem('hasAccess', 'true')
    }
  }, [])

  const navigateToLesson = id => {
    if (id > 10 && !paymentSuccess && !manualAccess) {
      alert(
        'Trebuie să finalizezi plata sau să introduci un cod de acces pentru a accesa această lecție.'
      )
      return
    }
    navigate(`/lectii/${id}/1`)
  }

  const handlePaymentSuccess = code => {
    setPaymentSuccess(true)
    setAccessCode(code)
    localStorage.setItem('hasAccess', 'true')
  }

  const handleCodeSubmit = () => {
    const correctCode = generateAccessCode()
    if (userCode === correctCode) {
      setManualAccess(true)
      localStorage.setItem('hasAccess', 'true')
    } else {
      alert('Codul de acces este incorect.')
    }
  }

  return (
    <div className='lesson-list-container'>
      {lessons
        .filter(lesson => lesson.id <= 10)
        .map(lesson => (
          <LessonRow
            key={lesson.id}
            lesson={lesson}
            onAccess={navigateToLesson}
          />
        ))}
      {!paymentSuccess && !manualAccess && (
        <div className='payment-section'>
          <h3>Vrei mai multe lectii ? </h3>
          <p>
            Plateste 10 dolari pentru a obtine un cod de acces valabil pentru
            luna curenta
          </p>
          <PayPalPayment onPaymentSuccess={handlePaymentSuccess} />
          <div className='code-input'>
            <p>Ai un cod de acces? Introdu-l mai jos:</p>
            <input
              type='text'
              value={userCode}
              onChange={e => setUserCode(e.target.value)}
              placeholder='Introdu codul de acces'
            />
            <Button variant='primary' onClick={handleCodeSubmit}>
              Validează Codul
            </Button>
          </div>
        </div>
      )}

      {(paymentSuccess || manualAccess) && (
        <>
          {lessons
            .filter(lesson => lesson.id > 10)
            .map(lesson => (
              <LessonRow
                key={lesson.id}
                lesson={lesson}
                onAccess={navigateToLesson}
              />
            ))}
          {paymentSuccess && (
            <div className='access-code'>
              <h4>Codul tău de acces este: {accessCode}</h4>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LessonList
